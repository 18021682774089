import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./HistItemDetail.css";
import { confirm } from "react-confirm-box";
import SublyApi from "../../.../../helpers/Api";
import GoalIcon from "../../Assets/Images/Goal.svg";
import TimeIcon from "../../Assets/Images/Time.svg";
import VoiceIcon from "../../Assets/Images/Voice.svg";
import duplicate from "../../Assets/Images/DuplicateIcon.svg";
import Nav from "../../Components/Header/header";
import ReactPlayer from "react-player";
import { Icon } from "@iconify/react";
import deletesvg from "../../Assets/Images/delete.svg";
import { logout, deleteToken } from "../../actions/currentUser";
import { Toast } from "../../commonFile";
import { getExcercise } from "../../actions/getExcercise";
import { SmallLoader } from "../../commonFile";
import { SharePopup } from "../commonShare";
import {
  deleteDefaultList,
  deleteNewDefaultList,
  setNewDefaultData,
} from "../../actions/defaultList";
import moment from "moment";
import i18n from "../i18n";
import { Getlanguage, VideoLanguageChange } from "../../commonfunction";
import { useTranslation } from "react-i18next";
import { getinternet } from "../../commonfunction";
import { clearErr } from "../../actions/errors";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";

const BASE_URL = process.env.REACT_APP_API_URL_TEXT;
function NewHistItemDetail() {
  const [loading, setLoading] = useState(false);
  const [adds, setadds] = useState(false);
  const [resultfail, setresultfail] = useState(false)
  const [newpopup, setnewpopup] = useState(false);
  const [shareBox, setShare] = useState(false);
  const [buttondisable, setbuttondisable] = useState(true);
  const [show, setshow] = useState();
  const history = useHistory();
  const { id } = useParams();
  const token = useSelector((st) => st.token);
  const dispatch = useDispatch();
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  }
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);

    }
    setlanguage();
  }, []);
  // -----logout when token expired-----

  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };


  // -----clear history API-----

  const clearSingleHistor = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      const result = await confirm(t("remthihisto"));
      if (result) {
        var formdata = new FormData();
        formdata.append("id", id);
        await SublyApi.singleDeleteHistory(token, formdata, currentUser.device_id, currentUser.device_timezone).then((responsejson) => {
          if (responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400) {
            signOut();
          }
          else {
            if (responsejson.data.status_code === 400) {
              Toast.fire({
                icon: "error",
                title: `${responsejson.data.message}`,
              });
            }
            else {
              if (result && responsejson.status_code == 200) {
                Toast.fire({
                  icon: "success",
                  title: t("histremsucc"),
                });
                history.push("/MyHistory")
              }
            }
          }
        });
      }
    }
  }

  const deleteFeedBack = async (idValue) => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      var formdata = new FormData();
      formdata.append("feedbackid", idValue);
      setbuttondisable(false)
      setresultfail(false)
      await SublyApi.feedbackDelete(token, formdata, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {
          if (responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400) {
            signOut();
            setTimeout(() => {
              setbuttondisable(true)
            }, 3000);
          }
          else {
            if (responsejson.data.status_code === 400) {
              Toast.fire({
                icon: "error",
                title: responsejson.data.message,
              }); setTimeout(() => {
                setbuttondisable(true)

              }, 3000);
            } else {
              Toast.fire({
                icon: "success",
                title: responsejson.message,
              });
              setTimeout(() => {
                setbuttondisable(true)

              }, 3000);
              setresultfail(true)
            }
          }
        })
        .catch(() => { });
    }
  };


  const [historyDetail, setHistoryDetailData] = useState("");

  // -----API to get history details data-----

  useEffect(() => {
    async function getHistoryDetail() {
      setLoading(true);
      const historyData = await SublyApi.exerciseDetailHistory(token, id, currentUser.device_id, currentUser.device_timezone);
      if (
        historyData &&
        historyData.data.error_type === "USER_NOT_FOUND" &&
        historyData.data.status_code === 400
      ) {
        signOut();
      }
      else {
        if (
          historyData &&
          historyData.data.status_code === 400
        ) {
        }
        if (historyData &&
          historyData.status_code === 200) {
          if (historyData) {
            if (historyData.data.exercise_history_detail.is_favorite == 1) {
              await setadds(true);
            } else {
              await setadds(false);
            }
          }
          historyData && historyData.data &&
            setHistoryDetailData({
              goal: historyData.data.exercise_history_detail.exercise.goal,
              durationMinutes:
                historyData.data.exercise_history_detail.exercise.duration_minutes,
              narration:
                historyData.data.exercise_history_detail.exercise.narration,
              theme: historyData.data.exercise_history_detail.exercise.theme,
              title: historyData.data.exercise_history_detail.exercise.title,
              createdAt: historyData.data.exercise_history_detail.created_at,
              Exid: historyData.data.exercise_history_detail.exercise.exerciseID,
              favAction: historyData.data.exercise_history_detail.is_favorite,
              experience_meta_id:
                historyData.data.exercise_history_detail.exercise.exerciseID,
              goal_id: historyData.data.exercise_history_detail.exercise.goal_id,
              duration_minute_id:
                historyData.data.exercise_history_detail.exercise
                  .duration_minute_id,
              narration_id:
                historyData.data.exercise_history_detail.exercise.narration_id,
              theme_id: historyData.data.exercise_history_detail.exercise.theme_id,
              trailer:
                historyData.data.exercise_history_detail.exercise.trailer_video
                  .trailer,
              exercise_id: historyData.data.exercise_history_detail.exercise_id,
              feedback_data: historyData.data.feedback_data,
              goal_description:
                historyData.data.exercise_history_detail.goal.goal_description,
              goal_video: historyData.data.exercise_history_detail.goal.goal_video,
              breathing_pace: historyData.data.exercise_history_detail.exercise.average_speed,
              language:  historyData.data.exercise_history_detail.exercise.language_id
            });
        }
      }
    }

    getHistoryDetail();
  }, [resultfail]);

  const addRemoveFav = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setbuttondisable(false);
      const historyDataNew = await SublyApi.exerciseDetailHistory(token, id, currentUser.device_id, currentUser.device_timezone);
      let action =
        historyDataNew.data.exercise_history_detail.is_favorite == 0 ? 1 : 0;

      await SublyApi.addFavorite(
        token,
        historyDataNew.data.exercise_history_detail.exercise_id,
        action, currentUser.isTokenFound, currentUser.device_timezone
      )
        .then((responsejson) => {
          if (responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400) {
            signOut();
            setTimeout(() => {
              setbuttondisable(true)
            }, 3000);
          }
          else {
            if (responsejson &&
              responsejson.data.status_code === 400) {
            }
            if (responsejson &&
              responsejson.status_code === 200) {

              if (responsejson.data.is_favorite == 1) {
                Toast.fire({
                  icon: "success",
                  title: t("introaddlike"),
                });
                setTimeout(() => {
                  setbuttondisable(true);
                }, 3000);
                setadds(true);
              } else {
                setTimeout(() => {
                  setbuttondisable(true);
                }, 3000);
                Toast.fire({
                  icon: "success",
                  title: t("introremlike"),
                });
                setadds(false);
              }
            }
          }
        })
        .catch(() => { });
    }
  };

  //replay the execise and create the new history for that
  const setReplayHistoryList = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setbuttondisable(false)
      const exerciseData = await SublyApi.exerciseDetail(
        token,
        historyDetail.exercise_id, currentUser.device_id, currentUser.device_timezone
      );
      if (
        exerciseData &&
        exerciseData.data.error_type === "USER_NOT_FOUND" &&
        exerciseData.data.status_code === 400
      ) {
        signOut();
        setbuttondisable(true)
      }
      else {
        if (
          exerciseData &&
          exerciseData.data.status_code === 400
        ) {
        }
        if (exerciseData.status_code === 200) {
          dispatch(deleteExcerciseList());
          dispatch(
            getExcercise([{
              exercise_video: exerciseData.data.exercise.exercise_video,
              exerciseid: exerciseData.data.exercise.exerciseID,
              after_questions: exerciseData.data.exercise.after_questions,
              before_questions: exerciseData.data.exercise.before_questions,
              is_favorite: exerciseData.data.exercise.is_favorite,
              goal_rating: exerciseData.data.exercise.goal_rating,
              duration_minutes: exerciseData.data.exercise.duration_minutes,
              average_speed: exerciseData.data.exercise.average_speed,
              exerciseID: exerciseData.data.exercise.exerciseID,
              trailer_video: exerciseData.data.exercise.trailer_video,
              is_like: exerciseData.data.exercise.is_like,
            }])
          );
          setbuttondisable(true)
          history.push("/Home/before-rating-section");
        }
      }
    }
  };
  //update the new default list for and redirect to home
  const setNewDuplicateList = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      await dispatch(
        setNewDefaultData([
          {
            default: {
              id: "1",
              goal_id: historyDetail.goal_id,
              age_group_id: "1",
              gender_id: "1",
              is_default: "1",
              experience_meta_id: historyDetail.experience_meta_id,
              duration_minute_id: historyDetail.duration_minute_id,
              narration_id: historyDetail.narration_id,
              theme_id: historyDetail.theme_id,
              minute: historyDetail.durationMinutes
            },
          },
        ])
      );
      history.push("/home/" + historyDetail.experience_meta_id);
    }
  };
  
  async function videoLanguageChange() {
    if (currentUser.default_language == historyDetail.language) {
      setNewDuplicateList()
    }
    else {
      const result = await confirm(t("changelanugage"),options);
      if (result == true) {
        history.push(`/Setting/Language-setting/${1}`)
      }
      else {
        setNewDuplicateList()
      }
    }

  }
  return (
    <>
      <Nav></Nav>
      <div className="newhistorydetailuperbody">
        <div
          className={`${newpopup || shareBox ? "fixedHis" : "newhistorydetailinnerbody"
            }`}
        >
          {historyDetail ? (
            <>
              <div className="newHisDetTitle">
                <div className="historylogo">
                  <div className="backimagetitle">
                    <div
                      style={{ marginLeft: "0px" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <span>
                        <Icon
                          icon="ion:chevron-back-outline"
                          color="white"
                          width="20px"
                          height="20px"
                          style={{ verticalAlign: "middle" }}
                        />
                      </span>
                    </div>

                    <div className="newhistoryexname">
                      <h2>{historyDetail.title}</h2>
                      <p>{moment.utc(historyDetail.createdAt).local().format("YYYY-MM-DD | hh:mm:ss")}</p>
                    </div>
                  </div>
                </div>
                <div className="newhistorydelete">
                  <div className="newdelicon" onClick={clearSingleHistor}>
                    <div className="icondelete">
                      <div className="delimageback">
                        <img src={deletesvg} alt="deletsvg"></img>
                      </div>
                    </div>
                    <div className="deletetitle">
                      <p style={{ marginBottom: "0px" }}>{t("REMOVEHISTORY")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="exerciseitemshow">
                <div className="item1block">
                  <div className="exercoseitembackground">
                    <div className="newhistoryitem" style={{ marginLeft: "-12px" }}>
                      <div className="goalicons">
                        <img src={GoalIcon} alt="goalicons"></img>
                      </div>
                      <div
                        className="goalicontitle "
                      >
                        <p>{historyDetail.goal}</p>
                        <span>
                          {t("GOAL_T")}
                          <Icon
                            icon="ant-design:info-circle-filled"
                            color="#29aae3"
                            width="20"
                            height="20"

                            style={{ cursor: "pointer" }}
                            onClick={() => setnewpopup(true)}
                          />
                          <span></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item2block">
                  <div className="exercoseitembackground">
                    <div
                      className="newhistoryitem"
                      style={{ marginLeft: "-5px" }}
                    >
                      <div className="goalicons">
                        <img src={TimeIcon} alt="TimeIcon"></img>
                      </div>
                      <div className="goalicontitle">
                        <p>{historyDetail.durationMinutes == -1 ? <Icon icon="typcn:infinity" color="grey" width="24" height="24" /> : historyDetail.durationMinutes}</p>
                        <span> {t("TIMEMIN_T")}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item3block">
                  <div className="exercoseitembackground">
                    <div
                      className="newhistoryitem"
                      style={{ marginLeft: "-9px" }}
                    >
                      <div className="goalicons">
                        <img src={VoiceIcon} alt="VoiceIcon"></img>
                      </div>
                      <div
                        className="goalicontitle"
                        style={{ marginLeft: "-5px" }}
                      >
                        <p>
                          {historyDetail.narration
                            ? historyDetail.narration
                            : "--"}
                        </p>
                        <span> {t("NARRATION")}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item5block">
                  <div className="exercoseitembackground responseextra exercoseitembackgrounds">
                    <div
                      className="newhistoryitem response_breath_icon"
                      style={{ marginLeft: "13px" }}
                    >
                      <div className="goalicons fixing_icon">
                        <Icon
                          icon="ph:waves-bold"
                          color="#4cabe3"
                          width="25"
                          height="25"
                        />
                      </div>
                      <div
                        className="goalicontitle response_bigtitle"
                        style={{ marginLeft: "17px", marginTop: "12px" }}
                      >
                        <p>
                          {historyDetail.breathing_pace
                            ? historyDetail.breathing_pace
                            : "--"}
                        </p>
                        <p
                          style={{
                            marginTop: "7px",
                            color: "#a8a4a4",

                            font: "normal normal normal 12px/18px Rubik",
                          }}
                        >
                          {t("BREATHPASE_T")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="allfeedback">
                <div className="feedbacknumber">
                  <p>{t("FEEDBACK")}({historyDetail.feedback_data.length})</p>
                </div>
                <div className="scroll_cus_feedback">
                  {historyDetail.feedback_data
                    ? historyDetail.feedback_data.map((item, index) => (
                      <div className="feedback_bottom_area" key={index}>
                        <div className="feedbackhistory">
                          <div className="feedbacktime">
                            <p>
                              {moment.utc(item.created_at).local().format("YYYY-MM-DD | hh:mm:ss")}
                            </p>
                          </div>
                          <div
                            style={buttondisable == false ? { pointerEvents: "none" } : {}}
                            className="feedbackdelete"
                            onClick={() => (buttondisable == true ? deleteFeedBack(item.exerciseFeedbackID) : "")

                            }
                          >
                            <span>
                              <img
                                src={deletesvg}
                                alt="deletsvg"
                                style={{ verticalAlign: "middle" }}
                              ></img>
                              <span
                                className="Deleteonefeedback"
                                style={{ marginLeft: "5px" }}
                              >
                                {t("DELETE")}
                              </span>
                            </span>
                          </div>
                        </div>
                        <span className="fulldiscription">
                          {item.description}
                        </span>
                      </div>
                    ))
                    : ""}
                </div>
                {historyDetail.feedback_data.length === 0 ? (
                  <p className="noDataFound">{t("NOFEEDBACK")}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="divider_Boxes" >
                <hr className="dividers"></hr>
              </div>
              <div className="newhistfootersec">
                <div
                  style={{ maxWidth: "34%", width: "100%" }}
                  className="changesresponse"
                >
                  <button
                    className="button_replay"
                    style={buttondisable == false ? { pointerEvents: "none" } : {}}
                    onClick={setReplayHistoryList}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <Icon
                        icon="ic:round-replay"
                        color="rgba(0, 0, 0, 0.9)"
                        width="25"
                        height="25"
                      />
                    </span>
                    {t("REPLAY")}
                  </button>
                </div>
                <div
                  style={{ maxWidth: "66%", width: "100%" }}
                  className="responsefooterwork2"
                >
                  <div className="footersecondpart">
                    <button onClick={videoLanguageChange}>
                      <span>
                        <span style={{ marginRight: "10px" }}>
                          <img
                            src={duplicate}
                            alt="duplicate"
                            width="20px"
                            height="20px"
                            style={{ verticalAlign: "middle" }}
                          />
                        </span>
                        {t("NEWDUPLICATE")}
                      </span>
                    </button>

                    <button
                      onClick={() =>
                        setShare(true)
                      }
                    >
                      <span style={{ marginRight: "10px" }}>
                        <Icon
                          icon="dashicons:share"
                          color="#FFFFFF"
                          width="20"
                          height="20"
                        />
                      </span>
                      {t("SHARE")}
                    </button>

                    <button style={buttondisable == false ? { pointerEvents: "none" } : {}} onClick={() => (buttondisable == true ? addRemoveFav() : "")} >
                      <span style={{ marginRight: "10px" }}>
                        {adds ? (
                          <Icon
                            icon="fa:heart"
                            color="white"
                            width="20"
                            height="20"
                            inline={true}
                          />
                        ) : (
                          <Icon
                            icon="fa:heart-o"
                            color="white"
                            width="20"
                            height="20"
                            inline={true}
                          />
                        )}
                      </span>
                      {t("ADDFAVORITES")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <SmallLoader />
          )}
        </div>
      </div>
      {/* ------this one is popup on goal------ */}
      <div className={newpopup == false ? "hide_player" : "backgrounone"}>
        <div className="Featurdgoalbody">
          <div className="Featureclaainnerbody">
            <div className="Featuretitlbody">
              <div className="title">
                <h2>{historyDetail.goal}</h2>
              </div>
              <div className="crossimage" style={{ alignSelf: "center" }}>
                <Icon
                  icon="bytesize:close"
                  color="white"
                  width="20"
                  height="20"
                  cursor="pointer"
                  onClick={() => setnewpopup(false)}
                />
              </div>
            </div>
            <div style={{ marginTop: "5px" }}>
              <div>
                <ReactPlayer
                  playsinline
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  url={historyDetail.goal_video}
                  controls
                  width="100%"
                  height="210px"
                  autoplay
                  muted={false}
                  playing={newpopup}
                />
              </div>
              <div style={{ marginTop: "26px" }}>
                <p className="goalinformation">
                  {historyDetail.goal_description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------Share popup on click------ */}
      <div className={shareBox ? "cares2" : "cares"}>
        <SharePopup
          shareBox={shareBox}
          setShareBox={setShare}
          trailer={historyDetail.trailer}
          BASE_URL={BASE_URL}
        />
      </div>
    </>
  );
}

export default NewHistItemDetail;
