import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_URL;
//const REACT_PROXYURL = "https://quiet-retreat-79741.herokuapp.com";
const REACT_PROXYURL = "";

/** API Class.
 *
 * Static class tying together methods used to get/send to to the API.
 * There shouldn't be any frontend-specific stuff here, and there shouldn't
 * be any API-aware stuff elsewhere in the frontend....
 *
 */

class SublyApi {
  //the token for interactive with the API will be stored here.
  static token;
  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${REACT_PROXYURL}${BASE_URL}/${endpoint}`;
    const headers = header || { Authorization: `Bearer ${SublyApi.token}` };
    //headers["Access-Control-Allow-Origin"] = "*";
    // "Access-Control-Allow-Origin": "*",
    //       "Content-Type": "application/json",
    const params = method === "get" ? data : {};
    try {
      axios.defaults.withCredentials = false;
      const res = await axios({ url, method, data, params, headers });
      return res ? res.data : "There is no return response for this route";
    } catch (err) {
      // if(err.response.data.error_type=='USER_NOT_FOUND' && err.response.data.status_code==400){
      //   return err.response;

      // }else{
      //   return err.response;
      // }
      return err.response;
    }
  }
  /******************************************************
   * USERS LOGIN API ROUTES
   *******************************************************/
  /** POST /serverErrorMessage:  { email, password }
   *
   * user Login
   *
   * Authorization required: none
   *
   * @param data {Object} {email, password}
   * @returns {object} {token}
   */
  static async userLogin(data, isTokenFound, timezone, changelanguage) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      "Timezone": timezone,
      "device-language": changelanguage,
      "Content-Type": "multipart/form-data",
    };

    let res = await this.request(`api/v1/auth/login`, data, "post", header);
    return res;
  }
  /** NOTE: The entire user object is
   *
   * user: { email, phone_dial_code, country_code, phone_number, password, confirm_password, firebase_token}
   *
   */

  /******************************************************
   * USERS AUTH API ROUTES
   *******************************************************/

  /** POST /auth/userRegister:   { data } => { token, user }
   *
   * user data must include { email, phone_dial_code, country_code, phone_number, password, confirm_password, firebase_token }
   *
   * (min, max) length restrictions ==> {email: (6, 30), username: (5, 20), password: (8,30), firstName & LastName: (2,20) ;
   *
   * email must be in email format
   *
   * Note: specific error messages come from JSONSCHEMA validation
   *
   * Authorization required: none
   *
   * @param data {Object} { username, password, firstName, lastName, email }
   * @returns {Object} {token, user (minus password)}
   */

  static async userSignUp(data, isTokenFound, timezone, changelanguage) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "device-language": changelanguage,
      "Content-Type": "multipart/form-data",
    };
    let res = await this.request(`api/v1/auth/signup`, data, "post", header);

    return res;
  }

  /** GET / =>
   *
   * Returns list of exercise history.
   *
   * Authorization required: Token
   *
   * @param token {string}
   * @returns {object} {users}
   **/
  static async exerciseHistory(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/exercise-history`,
      undefined,
      undefined,
      header
    );
    return res;
  }

    /** GET / =>
   *
   * Returns list of program exercises.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
    static async programExercises(token, isTokenFound, timezone) {
      const header = {
        "Device-Id": isTokenFound,
        "Device-Type": "3",
        Timezone: timezone,
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      let res = await this.request(
        `api/v1/library/program-exercises`,
        undefined,
        undefined,
        header
      );
      return res;
    }

  /** GET / =>
   *
   * Returns list of exercise history.
   *
   * Authorization required: Token
   *
   * @param token {string}
   * @param exerciseHistoryID {int}
   * @returns {object} {users}
   **/
  static async exerciseDetailHistory(token, exerciseHistoryID, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/exercise-history-detail/?exercisehistory_id=${exerciseHistoryID}`,
      undefined,
      undefined,
      header
    );
    return res;
  }

  /** GET / =>
   *
   * Returns list of exercise detail.
   *
   * Authorization required: Token
   *
   * @param token {string}
   * @param exerciseID {int}
   * @returns {object} {users}
   **/
  static async exerciseDetail(token, exerciseID, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/exercise/exercise-details?exercise_id=${exerciseID}`,
      undefined,
      undefined,
      header
    );
    return res;
  }

  /** GET / =>
   *
   * Remove All history.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async removeAllHistory(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/exercise-history-clear`,
      undefined,
      "post",
      header
    );

    return res;
  }

  /** GET / =>
   *
   * Returns list of exercise Favorite.
   *
   * Authorization required: Token
   *
   * @param token {string}
   * @returns {object} {users}
   **/

  static async exerciseFavorite(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/favorite-exercise`,
      undefined,
      undefined,
      header
    );
    return res;
  }

  /** GET / =>
   *
   * Returns exercise Favorite Detail.
   *
   * Authorization required: Token
   *
   * @param token {string}
   * @returns {object} {users}
   **/
  static async exerciseDetailFavorite(token, exercise_id, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/favorite-exercise-detail/?exercise_id=${exercise_id}`,
      undefined,
      undefined,
      header
    );

    return res;
  }

  /** GET / =>
   *
   * Remove All Favorite.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async removeAllFavorite(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/exercise-favouriteshistory-clear`,
      undefined,
      "post",
      header
    );

    return res;
  }

  /** GET / =>
   *
   * Remove single Favorite.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async removeSingleFavorite(token, exercise_id, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/favorite-exercise/${exercise_id}?action=0`,
      undefined,
      "post",
      header
    );
    return res;
  }

  /** GET / =>
   *
   * reset password
   *
   * Authorization required: none
   *
   * @param data {Object} {email}
   * @returns {object} {token}
   */

  static async resetPassword(data, isTokenFound, timezone, changelanguage) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      "device-language": changelanguage,
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
    };
    let res = await this.request(
      `api/v1/auth/reset-password`,
      data,
      "PATCH",
      header
    );

    return res;
  }

  /** GET / =>
   *
   * Add and Remove Favourite.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async addFavorite(token, exercise_id, action, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/favorite-exercise/${exercise_id}?action=${action}`,
      undefined,
      "post",
      header
    );
    return res;
  }



  /** GET / =>
   *
   * Personal Info .
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async personalInfo(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/general/profile_info`,
      undefined,
      undefined,
      header
    );

    return res;
  }

  /** GET / =>
   *
   * Personal Info .
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async personalInfoItem(token, currentUser, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/general/list?user_id=${currentUser.userID}`,
      undefined,
      undefined,
      header
    );

    return res;
  }

  /** GET / =>
   *
   * Personal Info .
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async surveyData(token, data, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(`api/v1/user/survey`, data, "PATCH", header);

    return res;
  }

  /** GET / =>
   *
   * Account Delete.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async accountDelete(token, currentUser, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/user-delete-account`,
      currentUser,
      "post",
      header
    );
    return res;
  }

  /** GET / =>
   *
   * Support API.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async SupportApi(requestData ,token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/auth/supportMail?ccb=${requestData.cc}
		&subject=${ requestData.subject}&supportMessage=${requestData.Message}`,
      undefined,
      undefined,
      header
    );

    return res;
  }
  
      /** GET / =>
   *
   * Returns list of whitelisted special plans
   *
   * @param token {string}
   **/
      static async WhitelistedUserPlans(token, email) {

        const header = {
          "Device-Id": "111",
          "Device-Type": "1",
          Timezone: "Asia/Calcutta",
        };
        let res = await this.request(
          `/api/v1/auth/whitelisted?email=${email}`,
          undefined,
          "GET",
          header
        );
    
        return res;
      }

        /** GET / =>
   *
   * Returns list of subscribed user plans
   *
   * @param token {string}
   **/
        static async SubscribedUserPlans(token, userID) {

          const header = {
            "Device-Id": "111",
            "Device-Type": "1",
            Timezone: "Asia/Calcutta",
          };
          let res = await this.request(
            `/api/v1/auth/subscribed?userid=${userID}`,
            undefined,
            "GET",
            header
          );
      
          return res;
        }

      /** GET / =>
   *
   * Returns user's calibration status
   *
   * @param token {string}
   **/
      static async GetUserCalibration(token, userID) {

        const header = {
          "Device-Id": "111",
          "Device-Type": "1",
          Timezone: "Asia/Calcutta",
        };
        let res = await this.request(
          `api/v2/user/get-user-calibration?userid=${userID}`,
          undefined,
          "GET",
          header
        );
    
        return res
      }

      /** GET / =>
   *
   * Returns user's average speed
   *
   * @param token {string}
   **/
      static async GetUserSpeed(token, userID) {

        const header = {
          "Device-Id": "111",
          "Device-Type": "1",
          Timezone: "Asia/Calcutta",
        };
        let res = await this.request(
          `api/v2/user/get-user-speed?userid=${userID}`,
          undefined,
          "GET",
          header
        );
    
        return res
      }

      /** POST / =>
   *
   * Sets user's average speed
   *
   * @param token {string}
   **/
      static async SetUserSpeed(token, Data, isTokenFound, timezone) {

        const header = {
          "Device-Id": isTokenFound,
          "Device-Type": "3",
          "Content-Type": "multipart/form-data",
          Timezone: timezone,
          Authorization: `Bearer ${token}`,
        };
        let res = await this.request(
          `api/v2/user/set-user-speed`,
          Data,
          "post",
          header
        );
        return res;
      }

  /** GET / =>
   *
   * Returns list of exercise history.
   *
   * Authorization required: Token
   *
   * @param token {string}
   * @param exerciseHistoryID {int}
   * @returns {object} {users}
   **/
  static async singleDeleteHistory(token, exerciseHistoryID, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/exercise-history`,
      exerciseHistoryID,
      "post",
      header
    );

    return res;
  }

  /** POST / =>
   *
   * Inserts a single record into exercise_history.
   *
   * Authorization required: Token
   *
   * @param token {string}
   * @param data 
   **/
  static async insertExerciseHistory(data, token, timezone) {
    const header = {
      "Device-Id": token,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/insert-exercise-history`,
      data,
      "post",
      header
    );
    return res;
  }

  /** GET / =>
   *
   * Returns list of exercise history.
   *
   * Authorization required: Token
   *
   * @param token {string}
   *
   *
   **/
  static async emailVerfication(token, email, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/auth/check-email-verification?email=${email}`,
      undefined,
      "GET",
      header
    );

    return res;
  }

  /** GET / =>
   *
   * Returns list of exercise history.
   *
   * Authorization required: Token
   *
   * @param token {string}
   *
   *
   **/
  static async ResendMailVerfication(token, email, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/auth/resend-verification-email?email=${email}`,
      undefined,
      "GET",
      header
    );

    return res;
  }

  /** POST / =>
   *
   * For Social signup.
   *
   * Authorization required: Token
   *
   * @param token {string}
   * @returns {object} {users}
   **/

  static async SocialLogin(data, isTokenFound, timezone, changelanguage) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "device-language": changelanguage,
      "Content-Type": "multipart/form-data",
    };
    let res = await this.request(
      `api/v1/auth/social-signup`,
      data,
      "post",
      header
    );

    return res;
  }

  /** POST / =>
   *
   * For Social signup.
   *
   * Authorization required: Token
   *
   * @param token {string}
   * @returns {object} {users}
   **/

  static async checkSocialLogin(data, isTokenFound, timezone, changelanguage) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "device-language": changelanguage,
      "Content-Type": "multipart/form-data",
    };
    let res = await this.request(
      `api/v1/auth/check-social-signup`,
      data,
      "post",
      header
    );

    return res;
  }

  /** GET / =>
   *
   * setting tutorial.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async settingTutorial(token, currentUser, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/general/list?user_id=${currentUser.userID}`,
      undefined,
      undefined,
      header
    );

    return res;
  }

  /** GET / =>
   *
   * get section.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async getHomeList(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/general/list?user_id=001`,
      undefined,
      undefined,
      header
    );
    return res;
  }

  /** GET / =>
   *
   * get section.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async setExcersiseDefault(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/exercise/set-default`,
      undefined,
      undefined,
      header
    );

    return res;
  }

  /** GET / =>
   *
   * get Goal By Theme.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async searchGoalTime(goalid, defaultsetting, token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };

    let res = await this.request(
      `api/v2/exercise/search?goal_id=${goalid}&narration_id=&theme_id=&duration_minute_id=&is_default=${defaultsetting}&output=TIME`,
      undefined,
      undefined,
      header
    );
    return res;
  }
  /** GET / =>
   *
   * get Goal By Theme.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async searchTimeNarration(timeID, goalid, defaultsetting, token, timeValue, isTokenFound, timezone) {
    // timeValue = -1;
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    if (timeValue == -1) {
      let res = await this.request(
        `api/v2/exercise/search?goal_id=${goalid}&narration_id=${null}&theme_id=&duration_minute_id=${timeID}&is_default=${defaultsetting}&output=NARRATION`,
        undefined,
        undefined,
        header
      );
      return res;
    } else {
      let res = await this.request(
        `api/v2/exercise/search?goal_id=${goalid}&narration_id=&theme_id=&duration_minute_id=${timeID}&is_default=${defaultsetting}&output=NARRATION`,
        undefined,
        undefined,
        header
      );

      return res;
    }
  }
  static async searchTimeTime(goalid, timeID, defaultsetting, token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };

    let res = await this.request(
      `api/v2/exercise/search?goal_id=${goalid}&narration_id=&theme_id=&duration_minute_id=${timeID}&is_default=${defaultsetting}&output=THEME`,
      undefined,
      undefined,
      header
    );

    return res;
  }

  /** GET / =>
   *
   * get Narration Theme.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async searchNarrationTheme(narrationId, goalid, timeID, defaultsetting, token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/exercise/search?goal_id=${goalid}&narration_id=${narrationId}&theme_id=&duration_minute_id=${timeID}&is_default=${defaultsetting}&output=THEME`,
      undefined,
      undefined,
      header
    );
    return res;
  }

  /** GET / =>
   *
   * get Narration Theme.
   *
   * Authorization required: Token
   *
   * @param token {string}
   **/
  static async getDefault(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/exercise/get_default?goal_id=1&duration_minute_id=1&narration_id=`,
      undefined,
      undefined,
      header
    );
    return res;
  }

  /** GET / =>
   *
   * get excersise home
   *
   * Authorization required: token,goalid,narrationid,timeid,themeid.
   *
   * @param token {string}
   **/
  static async getExcercise(themeid, goalid, timeID, narrationid, defaultsetting, token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };

    let res = await this.request(
      `api/v2/exercise/search?goal_id=${goalid}&narration_id=${narrationid}&theme_id=${themeid}&duration_minute_id=${timeID}&is_default=${defaultsetting}&output=EXERCISE`,
      undefined,
      undefined,
      header
    );

    return res;
  }

  /** GET / =>
   *
   * get feedback
   *
   * Authorization required: token,goalid,narrationid,timeid,themeid.
   *
   * @param token {string}
   **/
  static async getfeedback(data, token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/exercise/experience-feedback`,
      data,
      "post",
      header
    );

    return res;
  }

  /** GET / =>
   *
   * get feedback
   *
   * Authorization required: token,goalid,narrationid,timeid,themeid.
   *
   * @param token {string}
   **/
  static async feedback(data, token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api_v1/exercise/feedback`,
      data,
      "post",
      header
    );

    return res;
  }

  static async getsurvey() {
    const header = {
      "Device-Id": "name",
      "Device-Type": "3",
      Timezone: "Asia/Calcutta",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer`,
    };
    let res = await this.request(`api/v1/survey`, undefined, "GET", header);

    return res;
  }

  // it's api use for get age survey
  static async getfullsurvey(data, token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/survey/add-survey`,
      data,
      "post",
      header
    );
    return res;
  }

  /** GET / =>
   *
   * get User Information
   *
   * Authorization required: token.
   *
   * @param token {string}
   **/
  static async userInformation(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/user/user-information-view`,
      undefined,
      "GET",
      header
    );
    return res;
  }

  /** GET / =>
   *
   * get Account Details
   *
   * Authorization required: token.
   *
   * @param token {string}
   **/
  static async accountDetails(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/user/account-details`,
      undefined,
      "GET",
      header
    );
    return res;
  }

  /** GET / =>
   *
   * get Subscriptions
   *
   * Authorization required: token.
   *
   * @param token {string}
   **/
  static async manageSubscription(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/user/manage-subscriptions`,
      undefined,
      "GET",
      header
    );
    return res;
  }

  /** GET / =>
   *
   * Send Invitations
   *
   * Authorization required: token.
   *
   * @param token {string}
   **/
  static async sendInvitation(token, Data, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/user/invite-friends`,
      Data,
      "post",
      header
    );
    return res;
  }

  /** POST / =>
   *
   * UserInfo Update
   *
   * Authorization required: token.
   *
   * @param token {string}
   **/
  static async updateUserInfo(token, Data, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/user/user-information-update`,
      Data,
      "post",
      header
    );
    return res;
  }

  /** GET / =>
   *
   * get feedback
   *
   * Authorization required: token,goalid,narrationid,timeid,themeid.
   *
   * @param token {string}
   **/
  static async planSubscription(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/purchaseplan/plan-data`,
      undefined,
      "GET",
      header
    );
    return res;
  }

  /** POST / =>
   *
   * Update Langauge
   *
   * Authorization required: token.
   *
   * @param token {string}
   **/
  static async updateLanguage(token, Data, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/user/user-language`,
      Data,
      "post",
      header
    );
    return res;
  }

  /** POST / =>
   *
   * Password Change
   *
   * Authorization required: token.
   *
   * @param token {string}
   **/
  static async passwordChange(token, Data, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/user/change-password`,
      Data,
      "post",
      header
    );
    return res;
  }

  /** POST / =>
   *
   * set reminder
   *
   * Authorization required: token.
   *
   * @param token {string}
   **/
  static async setReminder(token, Data, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/user/set-reminder`,
      Data,
      "post",
      header
    );
    return res;
  }


  static async subsecription(token, Data, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/purchaseplan/purchase-subscription`,
      Data,
      "post",
      header
    );
    return res;
  }

  /** DELETE / =>
   *
   * delete feedback
   *
   * Authorization required: token.
   *
   * @param token {string}
   **/
  static async feedbackDelete(token, Data, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/library/exercise-feedback`,
      Data,
      "post",
      header
    );
    return res;
  }

  /** POST / =>
 *
 * delete subcription plan
 *
 * Authorization required: token.
 *
 * @param token {string}
 **/
  static async subcriptionSuspend(token, Data, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/purchaseplan/suspend-payment-recurring`,
      Data,
      "post",
      header
    );
    return res;
  }

  /** POST / =>
 *
 * user data download
 *
 * Authorization required: token.
 *
 * @param token {string}
 **/
  static async downloaddata(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/user/user-details-download`,
      undefined,
      "post",
      header
    );
    return res;
  }


  /** POST / =>
 *
 * get notificaton user
 *
 * Authorization required: token.
 *
 * @param token {string}
 **/
  static async getusernotification(data, token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/notification-list`,
      data,
      "post",
      header
    );
    return res;
  }
  /** POST / =>
*
* get user notification read or not 
*
* Authorization required: token.
*
* @param token {string}
**/
  static async getnotificationread(data, token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/update-notification`,
      data,
      "post",
      header
    );
    return res;
  }

  /** POST / =>
*
* logou use api
*
* Authorization required: token.
*
* @param token {string}
**/
  static async logoutuser(token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v1/auth/logout`,
      undefined,
      "get",
      header
    );
    return res;
  }


  /** POST / =>
*
* notification toggle status
*
* Authorization required: token.
*
* @param token {string}
**/
  static async notitooglestatus(data, token, isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `api/v2/update-push-status`,
      data,
      "post",
      header
    );
    return res;
  }

  /** POST / =>
*
* notification toggle status
*
* Authorization required: token.
*
* @param token {string}
**/
  static async termconditions(isTokenFound, timezone) {
    const header = {
      "Device-Id": isTokenFound,
      "Device-Type": "3",
      Timezone: timezone,
      "Content-Type": "multipart/form-data",
    };
    let res = await this.request(
      `api_v2/content/urls`,
      undefined,
      "GET",
      header
    );
    return res;
  }
  /** POST / =>
*
* notification toggle status
*
* Authorization required: token.
*
* @param token {string}
**/
  static async earlyaccess(data) {
    const header = {
      "Device-Id": "name",
      "Device-Type": "3",
      Timezone: "Asia/Calcutta",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer`,
    };
    let res = await this.request(
      `api/v2/user/early-access`,
      data,
      "post",
      header
    );
    return res;
  }

    /** POST / =>
*
* exercise like & dislike
*
* Authorization required: token.
*
* @param token {string}
**/
static async videolike(data,token,device_id,timezone) {
  const header = {
    "device-id": device_id,
    "device-type": "3",
    "timezone": timezone,
    "Content-Type": "multipart/form-data",
    Authorization:`Bearer ${token}`,
  };
  let res = await this.request(
    `api/v2/exercise/like-dislike`,
    data,
    "post",
    header
  );
  return res;
}

 /** POST / =>
*
* get gmail response
*
* Authorization required: token.
*
* gmail token is required
**/
static async gmailResponse(data,timezone,isTokenFound) {
  const header = {
    "device-id": isTokenFound,
    "device-type": "3",
    "timezone": timezone,
    
  };
  let res = await this.request(
    `api/v2/user/google-api`,
    data,
    "post",
    header
  );
  return res;
}
}




// for now, put token ("testuser" / "password" on class)
SublyApi.token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZ" +
  "SI6InRlc3R1c2VyIiwiaXNBZG1pbiI6ZmFsc2UsImlhdCI6MTU5ODE1OTI1OX0." +
  "FtrMwBQwe6Ue-glIFgz_Nf8XxRT2YecFCiSpYL0fCXc";

export default SublyApi;
