import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./signup.css";
import smalllogosignup from "../../../Assets/Images/Logo1.svg";
import emaillogo from "../../../Assets/Images/email.svg";
import passwordlogo from "../../../Assets/Images/password.svg";
import cpasswordlogo from "../../../Assets/Images/confirm password.svg";
import keylogo from "../../../Assets/Images/Key.svg";
import facebooksidelogo from "../../../Assets/Images/facebook.svg";
import googlesidelogo from "../../../Assets/Images/google.svg";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { getTokens, userSignUp } from "../../../actions/currentUser";
import { SmallLoader } from "../../../commonFile";
import { SocialLogin } from "../../../actions/currentUser";
import { checkSocialLogin } from "../../../actions/currentUser";
import { yupResolver } from "@hookform/resolvers/yup";
import SublyApi from "../../../helpers/Api";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import * as Yup from "yup";
import { Toast } from "../../../commonFile";
import { useTranslation } from "react-i18next";
import { Getlanguage } from "../../../commonfunction";
import { getTokenArr } from "../../../firebase";
import { getUser } from "../../../actions/currentUser";
import { getinternet } from "../../../commonfunction";
import { useGoogleLogin } from "@react-oauth/google";

function Signup() {

    const [challengeComplete, setChallengeComplete] = useState(false);

    useEffect(() => {
      const onloadTurnstileCallback = () => {
        /* global turnstile */
        turnstile.render("#content", {
          sitekey: process.env.REACT_APP_CF_TURNSTILE_SITEKEY,
          callback: function (token) {
            setTimeout(() => {
              setChallengeComplete(true);
            }, 1000);          
          },
        });
      };
  
      if (!challengeComplete) {
        const script = document.createElement("script");
        script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
        script.defer = true;
        const captchaMessage = document.createElement("h2");
        captchaMessage.textContent =
          "Checking if the site connection is secure and reviewing the security of your connection before proceeding...";
        captchaMessage.style.position = "absolute";
        captchaMessage.style.top = "calc(50% - 200px)";
        captchaMessage.style.left = "50%";
        captchaMessage.style.transform = "translate(-50%, -50%)";
        captchaMessage.style.textAlign = "center";
        captchaMessage.style.color = "white";
        captchaMessage.style.fontSize = window.innerWidth <= 768 ? "20px" : "35px"; // Adjust the breakpoint and font size values as needed
        const captcha = document.createElement("div");
        captcha.appendChild(script);
        captcha.appendChild(captchaMessage);
        script.onload = onloadTurnstileCallback;
        document.body.appendChild(captcha);
  
        return () => {
          document.body.removeChild(captcha);
        };
      }
    }, [challengeComplete]);

    function removeCaptcha() {
      var iframes = document.querySelectorAll('iframe[title="Widget containing a Cloudflare security challenge"]');
      iframes.forEach(function(iframe) {
        iframe.parentNode.removeChild(iframe);
      });
    }
  
    useEffect(() => {
      if (challengeComplete) {
        const captcha = document.querySelector("div[data-turnstile-container]");
        if (captcha) {
          captcha.style.opacity = 0;
          var scriptContent = document.querySelectorAll('iframe[title="Widget containing a Cloudflare security challenge"]');
          scriptContent.forEach(function(iframe) {
            iframe.parentNode.removeChild(iframe);
          });
          document.body.removeChild(captcha);
        }
        removeCaptcha();
      }
    }, [challengeComplete]);

  const [isTokenFound, setTokenFound] = useState();
  getTokenArr(setTokenFound);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : changelanguage));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  const currentUser = useSelector((st) => st.currentUser);
  // medtitaion value get by redux
  const experience = useSelector((st) => st.getexperiense);
  const language = useSelector((st) => st.getlanguage);
  const gender = useSelector((st) => st.getgender);
  const age = useSelector((st) => st.getsurvey);
  const [loading, setLoading] = useState(false);
  const GetLoginGmailResponse = useGoogleLogin({
    onSuccess: (codeResponse) => 
    userGmailResponse(codeResponse),
  });
  // ====Calling API for getting email detail through token====
  async function userGmailResponse(detail) {
    const timezone = await Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isOnline = await getinternet();
     if (isOnline == false) {
        Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
     } else {
       const requestData = new FormData();
       requestData.append("access_token", detail.access_token);
       await SublyApi.gmailResponse(
         requestData,
         timezone,isTokenFound
       ).then((responsejson) => {
         if (responsejson.status_code == 200) {
           responseGoogle(responsejson.data);
         } else if (responsejson.data.status_code == 400) {
          Toast.fire({
            icon: "error",
            title: `${responsejson.data.message}`,
          });
        }
      });
     }
   }
  // get survey convert to number because api get value in number
  const chanexperience = Number(experience.defaultData);
  const changeage = Number(age.defaultData);
  const changegender = Number(gender.defaultData);
  const changelanguage = Number(language.defaultData);

  const formOptions = {
    resolver: yupResolver(Yup.object().shape({
      email: Yup.string()
        .required(t("emailreq"))
        .matches(
          /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
          t("pentvalemail")
        ),

      password: Yup.string()
        .required(t("passwordrew"))
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/,
          t("signinpwderror")
        ),

      confirmPassword: Yup.string()
        .required(t("confirmpassreq"))
        .oneOf([Yup.ref("password")], t("passwordnotmatch")),
    }))
  };

  // it's work store a data in reducer.
  const dispatch = useDispatch();

  //sets state for form api response.
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  // if data send successfully then success message show by this state.
  const [successMessage, setSuccessMessage] = useState("");
  // it's use for input data validation & get data & setdata.it's a third party liabary.

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);
  const history = useHistory();
  // it's work take data input field help by register (third party ) & send data on api then response check & validation.
  const onSubmit = async (formData) => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      const timezone = await Intl.DateTimeFormat().resolvedOptions().timeZone;
      let requestData = new FormData();
      requestData.append("email", `${formData.email}`);
      requestData.append("password", `${formData.password}`);
      requestData.append("confirm_password", `${formData.confirmPassword}`);
      requestData.append("access_code", `${formData.accessCode}`);
      requestData.append("meditation", `${chanexperience ? chanexperience : ""}`)
      requestData.append("age_group", `${changeage ? changeage : ""}`);
      requestData.append("gender_id", `${changegender ? changegender : ""}`);
      requestData.append("default_language", `${changelanguage ? changelanguage : ""}`);
      requestData.append("firebase_token", `${isTokenFound ? isTokenFound : ""}`);
      setLoading(true);
      await SublyApi.userSignUp(requestData, isTokenFound, timezone, changelanguage ? changelanguage : "1")
        .then((responsejson) => {
          if (responsejson.status == 400 || responsejson.status == 500) {
            Toast.fire({
              icon: "error",
              title: `${responsejson.data.message}`,
            })
            setSuccessMessage("");
            setLoading(false);
          } else {
            dispatch(userSignUp(responsejson));
            dispatch(getUser({ isTokenFound: isTokenFound }));
            setServerErrorMessage("");
            Toast.fire({
              icon: "success",
              title: `${responsejson.message}`,
            })
            setTimeout(() => {
              if (responsejson.data.user_details.is_verified == 1) {
                if (responsejson.data.user_details.is_subscribe == 1) {
                  if (responsejson.data.user_details.is_survey_complete == 1) {
                    history.push("/new-tutorial")
                  }
                  else {
                    history.push("/login")
                  }
                }
                else {
                  history.push("/algorithm")
                }
              }
              else {
                history.push("/check")
              }
            }, 3000);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(true);
        });
    }
  };

  // -- Social Login --

  async function SocialSignUp(email, userID, social_type, picture) {
    const timezone = await Intl.DateTimeFormat().resolvedOptions().timeZone;
    var formdata = new FormData();
    formdata.append("email", email);
    formdata.append("social_id", userID);
    formdata.append("social_type", social_type);
    formdata.append("meditation", `${chanexperience ? chanexperience : ""}`)
    formdata.append("age_group", `${changeage ? changeage : ""}`);
    formdata.append("gender_id", `${changegender ? changegender : ""}`);
    formdata.append("default_language", `${changelanguage ? changelanguage : ""}`);
    formdata.append("firebase_token", isTokenFound ? isTokenFound : "");
    await SublyApi.checkSocialLogin(formdata, isTokenFound, timezone, changelanguage ? changelanguage : 1)
      .then(async (responsejson) => {
        let socialStatus = responsejson.data.social_status;
        if (socialStatus == 1) {
          dispatch(getTokens(responsejson.data.user_details.auth_token));
          dispatch(checkSocialLogin(responsejson));
          dispatch(getUser({ isTokenFound: isTokenFound }));
          history.push("/algorithm");
        } else {
          await SublyApi.SocialLogin(formdata, isTokenFound, timezone).then((responsejson) => {
            if (responsejson.data.status_code === 400) {
              Toast.fire({
                icon: "error",
                title: `${responsejson.data.message}`,
              });
            }
            else {
              Toast.fire({
                icon: "success",
                title: t("socialsuccess"),
              });
              dispatch(getTokens(responsejson.data.user_details.auth_token));
              dispatch(SocialLogin(responsejson))
              dispatch(getUser({ isTokenFound: isTokenFound }));
              history.push("/algorithm");
            }
          });
        }
      })
      .catch((error) => {
      });
  }

  // Google signup
  const responseGoogle = (response) => {
    if (response) {
      SocialSignUp(response.email, response.sub, 1);
    }

  };

  // Facebook signup
  const responseFacebook = (response) => {
    let res = response.status;
    if (res !== "unknown") {
      let userData = response;
      SocialSignUp(
        userData.email,
        userData.userID,
        2,
        userData.picture.data.url
      );
    }

  };

  //popup show or not show
  const [modalOpen, setModalOpen] = useState(false);

  const Click = () => {
    if (modalOpen == false) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };
  // password visible on click
  const eye = <FontAwesomeIcon icon={faEye} />;//image 
  const eye2 = <FontAwesomeIcon icon={faEyeSlash} />;// image
  const [passwordShown, setPasswordShown] = useState(false);// passwork show state
  const [imageShow, setImageshow] = useState(false);
  const [imageShow2, setImageshow2] = useState(false);

  const imagevisiblity = () => {
    setImageshow(imageShow ? false : true);
  };

  const imagevisiblity2 = () => {
    setImageshow2(imageShow2 ? false : true);
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [confirmpasswordShown, setconfirmPasswordShown] = useState(false);
  const togglePasswordVisiblity2 = () => {
    setconfirmPasswordShown(confirmpasswordShown ? false : true);
  };
  return (
    <div
      id="content"
      style={{
        display: challengeComplete ? "initial" : "flex",
        justifyContent: challengeComplete ? "initial" : "center",
        alignItems: challengeComplete ? "initial" : "center",
        height: challengeComplete ? "initial" : "100vh"
      }}
    >    {challengeComplete ? (
    <>
      <div className="container">
        <div className="logofirstsignup" >
          <button>
            <img style={{width: "294px"}} src={smalllogosignup} alt="" onClick={()=>history.push("/")} />
          </button>
        </div>
        <div className="singupfullbody">
          <div className="signupfullbodyflex">
            <div className="signupupperbody">
              <div className="signupinnerbody">
                <div className="signuptext">
                  <h3>{t("SIGNUPTITLE")}</h3>
                  <p>{t("ENTERYOURDET")}</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={false}>
                  <div className="signuphomebody ">
                    <div className="forgotformimage">
                      <img src={emaillogo} alt="emaillogo" width="23px"
                        height="23px" />
                    </div>
                    <input
                      autoFocus="autofocus"
                      autoComplete={false}
                      id="email"
                      name="email"
                      placeholder={t("enteremail")}
                      {...register("email")}
                      className="inputfieldauto"
                    />
                  </div>
                  <span className="errorMessageColor">
                    {errors.email?.message}
                  </span>

                  <div className="signuphomebody ">
                    <div className="forgotformimage">
                      <img src={passwordlogo} alt="passwordlogo" width="23px"
                        height="23px" />
                    </div>
                    <input
                      className="inputfieldauto"
                      autoComplete={false}
                      type={passwordShown ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder={t("enterpasswork")}
                      {...register("password")}
                    />

                    <i style={{ color: "white" }}
                      onClick={() => {
                        togglePasswordVisiblity();
                        imagevisiblity();
                      }}
                    >
                      {imageShow ? eye2 : eye}
                    </i>
                  </div>
                  <span className="errorMessageColor">
                    {errors.password?.message}{" "}
                  </span>

                  <div className="signuphomebody ">
                    <div className="forgotformimage">
                      <img src={cpasswordlogo} alt="cpasswordlogo" width="23px"
                        height="23px" />
                    </div>
                    <input
                      className="inputfieldauto"
                      id="confirmPassword"
                      name="confirmPassword"
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      type={confirmpasswordShown ? "text" : "password"}
                      placeholder={t("enterconfirm")}
                      {...register("confirmPassword")}
                      autoComplete="off"
                    />

                    <i style={{ color: "white" }}
                      className="eyelook"
                      onClick={() => {
                        togglePasswordVisiblity2();
                        imagevisiblity2();
                      }}
                    >
                      {imageShow2 ? eye2 : eye}
                    </i>
                  </div>
                  <span className="errorMessageColor">
                    {errors.confirmPassword?.message}{" "}
                  </span>

                  <div className="signuphomebody ">
                    <div className="forgotformimage">
                      <img src={keylogo} alt="keylogo" width="23px"
                        height="23px" />
                    </div>
                    <input
                      autoFocus="autofocus"
                      autoComplete={false}
                      id="accessCode"
                      name="accessCode"
                      placeholder={t("enteraccesscode")}
                      {...register("accessCode")}
                      className="inputfieldauto"
                    />
                  </div>
                  <span className="errorMessageColor">
                    {errors.accessCode?.message}{" "}
                  </span>

                  {(errors.confirmPassword == null &&
                    errors.phoneNumber == null &&
                    errors.password == null &&
                    errors.email) == null ? (
                    <p className="errorMessageColor">{serverErrorMessage}</p>
                  ) : (
                    ""
                  )}

                  


                  {loading ? <SmallLoader /> : null}
                  <button className="btn" type={`${loading ? "button" : "submit"}`}>
                    {t("SIGNUP")}
                  </button>
                </form>

                <div className="divider_Box">
                  <hr className="divide"></hr>
                </div>
                <div className="sociallogodiv">
                
                  <div className="sociallogoitem">                   
                        <button onClick={()=>GetLoginGmailResponse()}
                        >
                          <img src={googlesidelogo} alt="googlesidelogo" />
                        </button>
                  </div>
                  <div className="sociallogoitem facebookLogin">
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <button onClick={renderProps.onClick}>
                          <img src={facebooksidelogo} alt="faceb" />
                        </button>
                      )}
                    />
                  </div>
                </div>
                <div className="signupArea">
                  <div>
                    <p>{t("ALREADYACC")}</p>
                  </div>
                  <div>
                    <NavLink to="/login">{t("SIGNIN")}</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null}
  </div>
  );
}
export default Signup;