import React, { useState, useEffect } from "react";
import {  useHistory } from "react-router-dom";
import "./RatingSection.css";
import Nav from "../../Components/Header/header";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import GoalIcon from "../../Assets/Images/Goal.svg";
import breathimage from "../../Assets/Images/breathimage.png";
import TimeIcon from "../../Assets/Images/Time.svg";
import { Icon } from "@iconify/react";
import { removeTempData } from "../../actions/tempData";
import { Toast } from "../../commonFile";
import ReactRating from "react-rating";
import { getinternet } from "../../commonfunction";
import {
  BsFillEmojiNeutralFill,
  BsFillEmojiFrownFill,
  BsFillEmojiSmileFill,
  BsFillEmojiLaughingFill,
  BsFillEmojiHeartEyesFill,
} from "react-icons/bs";
import {
  BsEmojiNeutral,
  BsEmojiFrown,
  BsEmojiSmile,
  BsEmojiLaughing,
  BsEmojiHeartEyes,
} from "react-icons/bs";
import i18n from "../i18n";
import { Getlanguage } from "../../commonfunction";
import { deleteToken } from "../../actions/currentUser";
import { deleteDefaultList } from "../../actions/defaultList";
import { deleteNewDefaultList } from "../../actions/defaultList";
import { useTranslation } from "react-i18next";
import { logout } from "../../actions/currentUser";
import { clearErr } from "../../actions/errors";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";
function NewRatingSection() {
  const [newpopup, setnewpopup] = useState(false);
  const getExcercise = useSelector((st) => st.getExcercise);
  const temporaryData = useSelector((st) => st.tempData);
  const [ratingValue, setRatingValue] = useState(temporaryData.ratingValue);
  const [buttondisable, setbuttondisable] = useState(true);
  const dispatch = useDispatch();
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();


  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(
        parseInt(
          currentUser.default_language ? currentUser.default_language : 1
        )
      );
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };

  const customIcons = [
    <BsEmojiFrown className="ratingicons" size={34} />,
    <BsEmojiNeutral className="ratingicons" size={34} />,
    <BsEmojiSmile className="ratingicons" size={34} />,
    <BsEmojiLaughing className="ratingicons" size={34} />,
    <BsEmojiHeartEyes className="ratingicons " size={34} />,
  ];

  const fillIcons = [
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiFrownFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiNeutralFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiSmileFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiLaughingFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiHeartEyesFill className="fillColour " size={34} />
    </span>,
  ];

  const history = useHistory();

  //For Share Box PopUp

  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const token = useSelector((st) => st.token);
  const handleRating = (rate) => {
    setRatingValue(rate);
  };

  // ----here i am sending the data to API----

  const afterratingvalue = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setbuttondisable(false);
      const valueExperiense = localStorage.getItem("experiense");
      const feedbackValue = document.getElementById("textArea").value;
      let requestData = new FormData();
      requestData.append(
        "rating",
        temporaryData.ratingValue ? temporaryData.ratingValue : ratingValue
      );
      requestData.append("exercise_id", Object.values(getExcercise).map((item,index)=>{ return item.exerciseID}));
      requestData.append("feedback", feedbackValue ? feedbackValue : "");
      requestData.append("type", 2);
      requestData.append("experience_id", valueExperiense);
      requestData.append("firebase_token", currentUser.device_id);
      await SublyApi.getfeedback(requestData, token, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {
          if (
            responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400
          ) {
            // Toast.fire({
            //     icon: "error",
            //     title: responsejson.data.message,
            // });
            signOut();
            setbuttondisable(true);
          }
          else {
            if (responsejson.data.status_code === 400) {
              setServerErrorMessage(responsejson.data.message);
              Toast.fire({
                icon: "error",
                title: responsejson.data.message,
              });
              setbuttondisable(true);
            } else {
              setServerErrorMessage(responsejson.message);
              Toast.fire({
                icon: "success",
                title: responsejson.message,
              });
              dispatch(removeTempData());
              {getExcercise[0].goal_rating
                ? history.push("/home")
                : history.push("/program")}
              setbuttondisable(true);
            }
          }
        })
        .catch(() => { });
    }
  };
  return (
    <>
      <section>
        <Nav />
        <div
          className={`${newpopup ? "Ratingfullbody fixedRating" : "Ratingfullbody"
            }`}
        >
          <div className="Ratingupperbody neworkrating">
            <Icon
              icon="eva:arrow-ios-back-fill"
              color="white"
              width="30"
              height="30"
              style={{
                marginLeft: "-18px",
                position: "absolute",
                marginTop: "18px",
                cursor: "pointer",
              }}
              onClick={() => history.goBack()}
            />
            <div className="Ratingtitle">
              <h3>{getExcercise[0].after_questions}</h3>
            </div>

            <div className="RatingItems" style={{ marginTop: "54px" }}>
              <div className="RatingIcon_Text">
                <div className="dividerrule">
                  <ReactRating
                    initialRating={ratingValue}
                    emptySymbol={customIcons}
                    fullSymbol={fillIcons}
                    onClick={handleRating}
                  />
                </div>
                <div
                  className="ratingText1 ratingText"
                  style={{ width: "100%" }}
                >
                  <p>            
                    {getExcercise[0].goal_rating
                    ? getExcercise[0].goal_rating.low_rating
                    : getExcercise[0].low_rating}
                  </p>
                  </div>
                <div
                  className="ratingText2 ratingText"
                  style={{ width: "100%" }}
                >
                  <p>            
                    {getExcercise[0].goal_rating
                    ? getExcercise[0].goal_rating.high_rating
                    : getExcercise[0].high_rating}
                  </p>
                </div>
              </div>
            </div>
            <div className="rateleavefeedback">
              <p>{t("LEAVEFEEDBACK")}</p>
            </div>

            <div className="leavefeedbackbox">
              <textarea id="textArea" >{temporaryData.feedbackValue}</textarea>
            </div>

            <div className="ratingitemdetail">
              <div className="itemdetail">
                <div >
                  <div className="ratingitemflexbody">
                    <div className="ratingimageflex">
                      <img
                        src={GoalIcon}
                        alt="goalrating"
                        style={{ verticalAlign: "middle", cursor: "pointer" }}
                      />
                    </div>
                    <div>
                      <p className="rate_relax">
                        {getExcercise[0].goal_rating
                      ? getExcercise[0].goal_rating.goaltitle
                      : getExcercise[0].goal_title}
                      </p>
                      <p className="rate_relax2">
                        {t("GOAL_T")}
                        <Icon

                          icon="ant-design:info-circle-filled"
                          color="#29aae3"
                          width="20"
                          height="20"
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={() => setnewpopup(true)}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="ratingitemflexbody">
                    <div className="ratingimageflex">
                      <img
                        src={TimeIcon}
                        alt="timerating"
                        style={{ verticalAlign: "middle" }}
                      />
                    </div>
                    <div>
                      <p className="rate_relax">
                        {getExcercise[0].duration_minutes == -1 ? <Icon icon="typcn:infinity" color="grey" width="30" height="30" /> : getExcercise[0].duration_minutes}
                      </p>
                      <p className="rate_relax2">{t("TIMEMIN_T")}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="ratingitemflexbody">
                    <div>
                      <img
                        src={breathimage}
                        alt="timerating"
                        style={{ verticalAlign: "middle" }}
                      />
                    </div>
                    <div>
                      <p className="rate_relax">
                        {getExcercise[0].average_speed
                          ? getExcercise[0].average_speed
                          : "--"}
                      </p>
                      <p className="rate_relax2" style={{ maxWidth: "96px" }}>
                        {" "}
                        {t("BREATHPASE_T")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Ratingbuttonupper" style={buttondisable == false ? { pointerEvents: "none" } : {}}>
              <button
                className="Ratingsubmitbutton"
                onClick={() => (buttondisable == true ? afterratingvalue() : "")
                }
              >
                {t("SUBMITBUTTON")}
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className={newpopup == false ? "hide_player" : "backgrounone"}>
        <div className="Featurdgoalbody">
          <div className="Featureclaainnerbody changeInHeight">
            <div className="Featuretitlbody">
              <div className="title">
                <h2>
                  {getExcercise[0].goal_rating
                  ? getExcercise[0].goal_rating.goaltitle
                  : getExcercise[0].goal_title}
                </h2>
              </div>
              <div className="crossimage" style={{ alignSelf: "center" }}>
                <Icon
                  icon="bytesize:close"
                  color="white"
                  width="20"
                  height="20"
                  cursor="pointer"
                  onClick={() => setnewpopup(false)}
                />
              </div>
            </div>
            <div style={{ marginTop: "5px" }}>
              <div>
                <p className="goalinformation">
                  {getExcercise[0].goal_rating
                  ? getExcercise[0].goal_rating.goal_description
                  : getExcercise[0].goal_description}                
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewRatingSection;
