import React from "react";
import "../TermsConditions/Termsconditions.css";
import { useHistory } from "react-router-dom";
import Nav from "../../../Components/Header/header";
import { Icon } from "@iconify/react";
import { TRANSLATIONS_EN } from "../../../translations/en/translation";
function Privacy() {
  const history = useHistory();
  
  return (
    <>
      <section className="fullcontainerterm">
        <Nav></Nav>
        <div className="ouutercontainerterm">
          <div className="innercontainerterm">
            <div className="condition_Header">
              <div className="ConditionBack_Icon">
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="white"
                  width="24"
                  cursor="pointer"
                  height="24"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
              <h2>{TRANSLATIONS_EN.privacy_title}</h2>
            </div>
            <div className="terminnercontainertext">
              <p>
                {TRANSLATIONS_EN.privacyTitleDis}
                <a href="https://breathconductor.com">
                  {TRANSLATIONS_EN.webSIteLink}
                </a>
              </p>
              <p>{TRANSLATIONS_EN.privacyNextDis}</p>
              <p>{TRANSLATIONS_EN.privacyDisService}</p>
              <p>{TRANSLATIONS_EN.privacyReadCarefully}</p>
              <h2> {TRANSLATIONS_EN.collectionAndUse}</h2>
              <p>{TRANSLATIONS_EN.collectionDis}</p>
              <p>
                <span>{TRANSLATIONS_EN.note_to_Draft}</span>
                {TRANSLATIONS_EN.noteDisOne}
              </p>
              <p>
                <span>{TRANSLATIONS_EN.note_to_Draft}</span>
                {TRANSLATIONS_EN.noteDisTwo}
              </p>
              <p>{TRANSLATIONS_EN.afterNoteDis}</p>
              <p>{TRANSLATIONS_EN.additionInfo}</p>
              <p>{TRANSLATIONS_EN.contributions}</p>
              <p>{TRANSLATIONS_EN.yourValueTrust}</p>
              <h2> {TRANSLATIONS_EN.automaticInfo}</h2>
              <p>{TRANSLATIONS_EN.whenYouDownload}</p>
              <p>
                <ul>
                  <li>{TRANSLATIONS_EN.usageDetails}</li>
                  <li>{TRANSLATIONS_EN.DeviceInformation}</li>
                  <li>{TRANSLATIONS_EN.StoredInformation}</li>
                  <li>{TRANSLATIONS_EN.LocationInformation}</li>
                </ul>
              </p>

              <p>{TRANSLATIONS_EN.doNotDownload}</p>
              <p>{TRANSLATIONS_EN.useTechnologies}</p>
              <h2>{TRANSLATIONS_EN.othersite}</h2>
              <p>{TRANSLATIONS_EN.otherSiteDis}</p>
              <h2>{TRANSLATIONS_EN.ChildrensPrivacy}</h2>
              <p>{TRANSLATIONS_EN.forChildren}</p>
              <p>
                {TRANSLATIONS_EN.parentOrGuardian}
                <span>{TRANSLATIONS_EN.mainEmail}</span>
                {TRANSLATIONS_EN.afterEmail}
              </p>
              <p>{TRANSLATIONS_EN.residentsUnder}</p>
              <h2> {TRANSLATIONS_EN.privacyRights}</h2>
              <p>
                {TRANSLATIONS_EN.additionalRights}
                <li className="listSpace">
                  {TRANSLATIONS_EN.information}
                  <li>{TRANSLATIONS_EN.sourceInfo}</li>
                  <li>{TRANSLATIONS_EN.purposes}</li>
                </li>
                <li>{TRANSLATIONS_EN.access}</li>
                <li>{TRANSLATIONS_EN.deletion}</li>
                <li>{TRANSLATIONS_EN.optOut}</li>
              </p>
              <p>{TRANSLATIONS_EN.CCPALimits}</p>
              <p>{TRANSLATIONS_EN.exerciseRights}</p>
              <p>
                <span>{TRANSLATIONS_EN.submitRequest}</span>
              </p>
              <p>
                {TRANSLATIONS_EN.shineTheLight}
                <span>{TRANSLATIONS_EN.mainEmail}</span>
              </p>
              <p>{TRANSLATIONS_EN.verifyIdentity}</p>
              <p>
                <span>{TRANSLATIONS_EN.note_to_Draft}</span>
                {TRANSLATIONS_EN.counrtySpecific}
              </p>
              <p>{TRANSLATIONS_EN.authorizedAgents}</p>
              <h2>{TRANSLATIONS_EN.changePolicy}</h2>
              <p>{TRANSLATIONS_EN.updatePolicy}</p>
              <p>{TRANSLATIONS_EN.periodicallyVisiting}</p>
              <p>{TRANSLATIONS_EN.changePolicyLast}</p>
              <h2>{TRANSLATIONS_EN.ContactUs}</h2>
              <p>
                {TRANSLATIONS_EN.contactUsAt}
                <span>{TRANSLATIONS_EN.mainEmail}</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacy;
