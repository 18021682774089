import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./RatingSection.css";
import Nav from "../Header/header";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { Toast } from "../../commonFile";
import { removeTempData } from "../../actions/tempData";
import ReactRating from "react-rating";
import {
  BsFillEmojiNeutralFill,
  BsFillEmojiFrownFill,
  BsFillEmojiSmileFill,
  BsFillEmojiLaughingFill,
  BsFillEmojiHeartEyesFill,
} from "react-icons/bs";
import {
  BsEmojiNeutral,
  BsEmojiFrown,
  BsEmojiSmile,
  BsEmojiLaughing,
  BsEmojiHeartEyes,
} from "react-icons/bs";
import i18n from "../i18n";
import { Getlanguage } from "../../commonfunction";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { getinternet } from "../../commonfunction";
import { logout } from "../../actions/currentUser";
import { deleteDefaultList } from "../../actions/defaultList";
import { clearErr } from "../../actions/errors";
import { deleteToken } from "../../actions/currentUser";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";
import { deleteNewDefaultList } from "../../actions/defaultList";


function BeforeRatingSection() {
  const [ratingValue, setRatingValue] = useState(0);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const currentUser = useSelector((st) => st.currentUser);
  const token = useSelector((st) => st.token);
  // const defaultList = useSelector((st) => st.defaultList);
  const getExcercise = useSelector((st) => st.getExcercise);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(
        parseInt(
          currentUser.default_language ? currentUser.default_language : 1
        )
      );
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  const handleRating = (rate) => {
    setRatingValue(rate);
  };

  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };
  const customIcons = [
    <BsEmojiFrown className="ratingicons" size={34} />,
    <BsEmojiNeutral className="ratingicons" size={34} />,
    <BsEmojiSmile className="ratingicons" size={34} />,
    <BsEmojiLaughing className="ratingicons" size={34} />,
    <BsEmojiHeartEyes className="ratingicons " size={34} />,
  ];

  const fillIcons = [
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiFrownFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiNeutralFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiSmileFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiLaughingFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiHeartEyesFill className="fillColour " size={34} />
    </span>,
  ];
  const ratingvalue = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      let requestData = new FormData();
      requestData.append("rating", ratingValue);
      requestData.append("exercise_id", Object.values(getExcercise).map((item, index) => { return item.exerciseID }))
      requestData.append("type", 1);
      requestData.append("firebase_token", currentUser.device_id);

      // -----API for giving rating -----

      await SublyApi.getfeedback(requestData, token, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {
          if (
            responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400
          ) {
            // Toast.fire({
            //     icon: "error",
            //     title: responsejson.data.message,
            // });
            signOut();
          }
          else {
            if (responsejson.data.status_code === 400) {
              setServerErrorMessage(responsejson.data.message);
              Toast.fire({
                icon: "error",
                title: responsejson.data.message,
              });
            } else {
              setServerErrorMessage(responsejson.message);
              if (responsejson.data.experience.experienceID) {
                localStorage.setItem(
                  "experiense",
                  responsejson.data.experience.experienceID
                );
              }
              else {
                localStorage.setItem(
                  "experiense",
                  responsejson.data.experience
                );
              }
              // Toast.fire({
              //   icon: "success",
              //   title: responsejson.message,
              // });
              history.push("/Home/new-Intro");

            }
          }
        })
        .catch((error) => { });
    }
  };

  return (
    <>
      <section>
        <Nav />
        <div className="Ratingfullbody" style={{ marginBottom: "100px" }}>
          <div className="Ratingupperbody" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
            , paddingBottom: "0px"
          }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "100%", width: "100%" }}>
              <div style={{ display: "flex", justifyContent: "space-between", margin: "auto 0px", height: "100%", width: "100%" }}>
                <div style={{ width: "10%" }}>
                  <Icon
                    icon="eva:arrow-ios-back-fill"
                    color="white"
                    width="30"
                    height="30"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => history.goBack()}
                  />
                </div>
                <div className="Ratingtitle" style={{ marginRight: "25px", width: "90%" }}>
                  <h3 style={{ marginTop: "0px", marginBottom: "0px" }}>{getExcercise[0].before_questions}</h3>
                </div>
              </div>
              <div className="RatingItems" style={{ marginTop: "76px" }}>
                <div className="RatingIcon_Text">
                  <div className="dividerrule">
                    <ReactRating
                      initialRating={ratingValue}
                      emptySymbol={customIcons}
                      fullSymbol={fillIcons}
                      onClick={handleRating}
                    />
                  </div>
                  <div
                    className="ratingText1 ratingText"
                    style={{ width: "100%" }}
                  >
                    <p>
                        {getExcercise[0].goal_rating
                        ? getExcercise[0].goal_rating.low_rating
                        : getExcercise[0].low_rating}
                    </p>                  
                  </div>
                  <div
                    className="ratingText2 ratingText"
                    style={{ width: "100%" }}
                  >
                    <p>
                        {getExcercise[0].goal_rating
                        ? getExcercise[0].goal_rating.high_rating
                        : getExcercise[0].high_rating}
                    </p>
                  </div>
                </div>
              </div>
              <div className="Ratingbuttonupper btn_SpaceManage" style={{ paddingBottom: "6px" }}>
                <button
                  className="Ratingsubmitbutton"
                  onClick={() => {
                    ratingvalue();
                    dispatch(removeTempData());
                  }}
                >
                  {t("SUBMITBUTTON")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BeforeRatingSection;
