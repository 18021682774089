import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Program.css";
import Nav from "../../Components/Header/header";
import SublyApi from "../../.../../helpers/Api";
import { logout, deleteToken } from "../../actions/currentUser";
import { Loader } from "../../commonFile";
import {
  deleteDefaultList,
  deleteNewDefaultList,
} from "../../actions/defaultList";
import { useHistory } from "react-router-dom";
import { Getlanguage } from "../../commonfunction";
import { getExcercise } from "../../actions/getExcercise";
import { useTranslation } from "react-i18next";
import { getinternet } from "../../commonfunction";
import { clearErr } from "../../actions/errors";
import NextArrow from "../../Assets/Images/next.svg";
import { Toast } from "../../commonFile";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";

function Program() {
  const [programDataList, setFormData] = useState(null);
  const [resultfail, setresultfail] = useState()
  const [loading, setLoading] = useState(false);
  const [notfound, setNotFound] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((st) => st.token);
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);


  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };

  useEffect(() => {
    async function getProgramExercises() {
      setLoading(true);
      const programData = await SublyApi.programExercises(token, currentUser.device_id, currentUser.device_timezone);
      if (
        programData &&
        programData.data.error_type === "USER_NOT_FOUND" &&
        programData.data.status_code === 400
      ) {
        signOut();
      }
      else {
        if (programData) {
          if (programData.data.data_found == false) {
            setLoading(false);
            setNotFound(programData.message);
          } else {
            setLoading(false);
          }
        }
        setFormData(programData ? programData.data.program_exercises_list : "");
      }
    }

    getProgramExercises(programDataList);
  }, [resultfail === true]);

  const beginExercise = async (exercise) => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
		var formdata = new FormData();
    	formdata.append("exercise_id", exercise.exerciseID);
		await SublyApi.insertExerciseHistory(formdata, token, currentUser.device_timezone);
          dispatch(deleteExcerciseList());
          dispatch(
            getExcercise([{
              exercise_video: 
			  "https://breathconductor-com.s3.us-east-2.amazonaws.com/uploads/exercise/complete/" 
			  + exercise.exercise_video,
              exerciseid: exercise.exerciseID,
              after_questions: exercise.after_questions,
              before_questions: exercise.before_questions,
              is_favorite: exercise.is_favorite,
              goal_rating: exercise.goal_rating,
              duration_minutes: exercise.duration_minutes,
              average_speed: exercise.average_speed,
              exerciseID: exercise.exerciseID,
              trailer_video: exercise.trailer_video,
              is_like: exercise.is_like,
			  goal: exercise.goal,
			  goal_title: exercise.goal_title,
			  goal_description: exercise.goal_description,
			  low_rating: exercise.low_rating,
			  high_rating: exercise.high_rating,
			  trailer: "",
            }])
          );
          history.push("/Home/before-rating-section");
    }
  };
  
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1)
  const filteredProgramData = programDataList
    ? programDataList.filter(item => new Date(item.due_date) >= currentDate)
    : [];

	return (
		<section className={`ProgramBody ${resultfail === false ? "displaynone" : "ProgramBody"}`}>
		  <Nav />
	  
		  <div className="programpopup">
			<div className="ProgramContain">
			  <div className="ProgHeader">
				<div className="ProgTitle">
				  <h2> {t("PROGRAM")}</h2>
				</div>
				{filteredProgramData.length > 0 ? (
				  <div className="ProgBtn">
				  </div>
				) : (
				  ""
				)}
			  </div>
			  <span>{loading ? <Loader /> : null}</span>
			  <div style={{ paddingBottom: "39px", marginTop: "5px" }}>
				<div className="ProgList">
				  {filteredProgramData.length > 0 ? (
					filteredProgramData
					  .slice()
					  .sort((a, b) => {
						const dateA = new Date(a.due_date);
						const dateB = new Date(b.due_date);
						return dateA - dateB;
					  })
					  .map((item, index) => (
						<div className="ProgItem" key={index} onClick={() => beginExercise(item)}>
						  <div className="ProgText">
							<h3>{item.title.toLowerCase()}</h3>
						  </div>
						  <div className="ProgIcon">
							<span>
							  Goal: {item.goal_title} &emsp; &emsp; Due Date: {item.due_date}
							</span>
							<img src={NextArrow} alt="arrow" />
						  </div>
						</div>
					  ))
				  ) : (
					<p className="notrecord">{t("No items with valid due dates.")}</p>
				  )}
				</div>
			  </div>
			</div>
		  </div>
		</section>
	  );	  
	  
}

export default Program;