import React from "react";
import { useHistory } from "react-router-dom";
import "./Termsconditions.css";
import Nav from "../../../Components/Header/header";
import { Icon } from "@iconify/react";
import { TRANSLATIONS_EN } from "../../../translations/en/translation";
function Terms() {
  const history = useHistory();
  return (
    <>
      <section className="fullcontainerterm">
        <Nav></Nav>
        <div className="ouutercontainerterm">
          <div className="innercontainerterm">
            <div className="condition_Header">
              <div className="ConditionBack_Icon">
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="white"
                  width="24"
                  cursor="pointer"
                  height="24"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
              <h2>{TRANSLATIONS_EN.term_title}</h2>
            </div>
            <div className="terminnercontainertext">
              <p>
                {TRANSLATIONS_EN.term_title_disc}
              </p>
              <h2> {TRANSLATIONS_EN.term_copyright}</h2>
              <p>
                {TRANSLATIONS_EN.term_copyright_disc}
              </p>
              <h2> {TRANSLATIONS_EN.term_app}</h2>
              <p>
                {TRANSLATIONS_EN.term_app1}
              </p>
              <p>
                {TRANSLATIONS_EN.term_app2}
              </p>
              <p>
                {TRANSLATIONS_EN.term_app3}
              </p>
              <p>
                {TRANSLATIONS_EN.term_app4}
              </p>
              <p>
                {TRANSLATIONS_EN.term_app5}
              </p>
              <p>
                {TRANSLATIONS_EN.term_app6}
              </p>
              <p>
                {TRANSLATIONS_EN.term_app7}
              </p>
              <p>
                {TRANSLATIONS_EN.term_app8}
              </p>
              <p>
                {TRANSLATIONS_EN.term_app9}
              </p>
              <h2>  {TRANSLATIONS_EN.term_website_link}</h2>
              <p>
                {TRANSLATIONS_EN.term_website_link_disc}
              </p>
              <h2> {TRANSLATIONS_EN.term_loop}</h2>
              <p className="changeLoopList">
                {TRANSLATIONS_EN.term_loop_disc}
                <li>{TRANSLATIONS_EN.term_loop_One}</li>
                <li>{TRANSLATIONS_EN.term_loop_Two}</li>
              </p>
              <h2>{TRANSLATIONS_EN.term_con_chan}</h2>
              <p>
                {TRANSLATIONS_EN.term_con_chan_disc}
              </p>
              <h2>{TRANSLATIONS_EN.term_contact}</h2>
              <p>
                {TRANSLATIONS_EN.term_contact_disc}
                <span>{TRANSLATIONS_EN.mainEmail}</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Terms;
