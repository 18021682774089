import "./IntroVideo.css";
import SublyApi from "../../helpers/Api";
import { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import Feedback from "../../Assets/Images/feedback.svg";
import { Toast } from "../../commonFile";
import back from "../../Assets/Images/back.svg";
import Nav from "../../Components/Header/header";
import { AiFillHeart } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillStepForward } from "react-icons/ai";
import { Icon } from "@iconify/react";
import FeedBackRatting from "./Feedback/newFeedBack";
import { getExcercise } from "../../actions/getExcercise";
import { SharePopup } from "../commonShare";
import { Getlanguage } from "../../commonfunction";
import { useTranslation } from "react-i18next";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";
import { clearErr } from "../../actions/errors";
import { getinternet } from "../../commonfunction";
import { deleteToken } from "../../actions/currentUser";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteNewDefaultList } from "../../actions/defaultList";
import { deleteAge } from "../../actions/getsurvey";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteDefaultList } from "../../actions/defaultList";
import { logout } from "../../actions/currentUser";
import moment from "moment";
import continues from "../../Assets/Images/icons/continue.svg";
import discontinue from "../../Assets/Images/icons/discontinue.svg";
import motivates from "../../Assets/Images/icons/motivate.svg";
import motivatefull from "../../Assets/Images/icons/motivatefull.svg";
import demotivates from "../../Assets/Images/icons/demotivate.svg";
import demotivatefull from "../../Assets/Images/icons/demotivatfull.svg";
const BASE_URLS = process.env.REACT_APP_API_URL_TEXT;
const IntroVdo = () => {
  const inputEl = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((st) => st.token);
  const getExcerciseData = useSelector((st) => st.getExcercise);
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  const [shareBox, setShare] = useState(false);
  const [getFeedback, setFeedback] = useState(false);
  const [getCurrentValue, setCurrentTime] = useState();
  const [infoshow, setinforshow] = useState(false);
  const [buttondisable, setbuttondisable] = useState(true);
  const [loops, setloops] = useState(false)
  const [playingIndex, setPlayingIndex] = useState(0);
  // this state use for looping & like & dislike & favourite array update
  const [getallvideo, setallvideo] = useState(Object.values(getExcerciseData));
  const [getexercisevideo, setexercisevido] = useState(getallvideo);
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click context menu
  };
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, [])

  function logouts() {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(deleteNewDefaultList())
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    history.push("/login");
  };

  const introVdoDuration = moment(getexercisevideo[playingIndex].intro_duration, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds');
  const totalVdoDuration = moment(getexercisevideo[playingIndex].exercise_duration, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds');
  function skipvideo() {
    const refvalue = inputEl;

    const refvalueduration = refvalue.current.getDuration();
    var func =
      refvalueduration > introVdoDuration
        ? refvalue.current.seekTo(introVdoDuration)
        : null;
  }
  //it's function for like dislike one by one video behalf of exercise id
  const addRemoveFav = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      let action = await getexercisevideo[playingIndex].is_favorite == 1 ? 0 : 1;
      let exerciseid = await Number(getexercisevideo[playingIndex].exerciseID);
      await SublyApi.addFavorite(token, exerciseid, action, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {
          if (
            responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400
          ) {
            logouts();
          }
          else {
            if (responsejson.data.status_code == 400) {

              Toast.fire({
                icon: "error",
                title: responsejson.data.message,
              });
            } else {
              if (responsejson.data.is_favorite == 1) {
                Toast.fire({
                  icon: "success",
                  title: t("introaddlike"),
                });
                if (getexercisevideo.some(urls => urls.exercise_video == inputEl.current.props.url) == true) {
                  const getlikeindex = getallvideo.findIndex(urls => urls.exercise_video == inputEl.current.props.url);
                  getallvideo[getlikeindex].is_favorite = 1
                  dispatch(getExcercise(getallvideo))
                }
              } else {
                Toast.fire({
                  icon: "success",
                  title: t("introremlike"),
                });
                if (getexercisevideo.some(urls => urls.exercise_video == inputEl.current.props.url) == true) {
                  const getlikeindex = getallvideo.findIndex(urls => urls.exercise_video == inputEl.current.props.url);
                  getallvideo[getlikeindex].is_favorite = 0
                  dispatch(getExcercise(getallvideo))
                }
              }
            }

          }
        })
        .catch(() => { });
    }
  };


  // about goalinformationwork onclick  
  function openinformation() {
    infoshow === false ? setinforshow(true) : setinforshow(false);
  }
  //it's function auto clik after video end but it's condition if loop is false
  function videoend() {
    if (getallvideo && getallvideo.length == 1 && getallvideo.some(url => url.duration_minutes == -1) == false) {
      history.push("/Home/new-after-rating")
    }
    else {
      if (getallvideo && getallvideo.length >= 1 && getallvideo.some(url => url.duration_minutes == -1) == true) {
        if (getexercisevideo.length - 1 !== playingIndex) {
          setPlayingIndex(duration => ++duration)
        }
        else {
          setPlayingIndex(0)
        }
      }
    }
  }
  //it's function loop work if user click first time then array value send 1 but after second click whole array value send in array

  function getvideo() {
    const duration = getCurrentValue && getCurrentValue.playedSeconds;
    if (loops == false) {
      setPlayingIndex(0)
      const getindexonevideo = getallvideo.find(value => value.exercise_video == inputEl.current.props.url)
      setexercisevido([getindexonevideo])
      setloops(true)
    }
    else {
      if (loops == true) {
        setexercisevido([inputEl.current.props.url]);
        const getindex = getallvideo.findIndex(value => value.exercise_video == inputEl.current.props.url)
        const nextvideoes = getallvideo.slice(getindex + 1, getallvideo.length + 1)
        const previousvideoes = getallvideo.slice(0, getindex)
        setexercisevido(existingItems => {
          return [...getexercisevideo, ...nextvideoes, ...previousvideoes

          ]
        })
        inputEl.current.seekTo(duration);
      }
      setloops(false)
    }
  }

  //it's function array value update like & dislike thumb.
  async function likevideos() {
    const exerciseid = await getexercisevideo[playingIndex].exerciseID;
    const exerciselike = await getexercisevideo[playingIndex].is_like;

    let requestData = new FormData();
    requestData.append("exercise_id", Number(exerciseid));
    requestData.append("is_like", exerciselike == 0 || exerciselike == 2 ? 1 : 0);
    await SublyApi.videolike(requestData, token, currentUser.device_id, currentUser.device_timezone)
      .then((responsejson) => {
        if (
          responsejson &&
          responsejson.data.error_type == "USER_NOT_FOUND" &&
          responsejson.data.status_code === 400
        ) {
          logouts();
        }
        else {
          if (responsejson.data.status_code == 400 || responsejson.status == 404) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
          } else {
            if (getexercisevideo.some(urls => urls.exercise_video == inputEl.current.props.url) == true) {
              const getlikevalue = getallvideo.find(urls => urls.exercise_video == inputEl.current.props.url);
              const getlikeindex = getallvideo.findIndex(urls => urls.exercise_video == inputEl.current.props.url);
              if (getlikevalue.is_like == 0 || getlikevalue.is_like == 2) {
                getallvideo[getlikeindex].is_like = 1
                dispatch(getExcercise(getallvideo))
                setbuttondisable(true)
              }
              else {
                if (getlikevalue.is_like == 1) {
                  getallvideo[getlikeindex].is_like = 0
                  dispatch(getExcercise(getallvideo))
                  setbuttondisable(true)
                }
              }
            }
          }
        }
      })
      .catch(() => { });

  }
  //it's function array value update like & dislike thumb.

  async function removedislikevideo() {
    const exerciseid = await getallvideo.find(urls => urls.exercise_video == inputEl.current.props.url);
    let requestData = new FormData();
    requestData.append("exercise_id", Number(exerciseid.exerciseID));
    requestData.append("is_like", exerciseid.is_like == 0 || exerciseid.is_like == 1 ? 2 : 0);
    await SublyApi.videolike(requestData, token, currentUser.device_id, currentUser.device_timezone)
      .then((responsejson) => {
        if (
          responsejson &&
          responsejson.data.error_type == "USER_NOT_FOUND" &&
          responsejson.data.status_code === 400
        ) {
        }
        else {
          if (responsejson.data.status_code === 400) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
          } else {
            if (getexercisevideo.some(urls => urls.exercise_video == inputEl.current.props.url) == true) {
              const getlikevalue = getallvideo.find(urls => urls.exercise_video == inputEl.current.props.url);
              const getlikeindex = getallvideo.findIndex(urls => urls.exercise_video == inputEl.current.props.url);
              if (getlikevalue.is_like == 0 || getlikevalue.is_like == 1) {
                getallvideo[getlikeindex].is_like = 2
                dispatch(getExcercise(getallvideo))
              }
              else {
                if (getlikevalue.is_like == 2) {
                  getallvideo[getlikeindex].is_like = 0
                  dispatch(getExcercise(getallvideo))
                }
              }
            }
          }
        }
      })
      .catch(() => { });
  }
  //it's function work for skipone videos but loop should be false
  async function skipvideos() {
    inputEl.current.seekTo(0);
    if (loops == true) {
      setloops(false)
      setexercisevido([inputEl.current.props.url]);
      const getindex = getallvideo.findIndex(value => value.exercise_video == inputEl.current.props.url)
      const nextvideoes = getallvideo.slice(getindex + 1, getallvideo.length + 1)
      const previousvideoes = getallvideo.slice(0, getindex)
      setexercisevido(existingItems => {
        return [...nextvideoes, ...previousvideoes, ...getexercisevideo
        ]
      })
    }
    if (getallvideo && getallvideo.length > 1 && getallvideo.some(url => url.duration_minutes == -1) == true) {
      if (loops == false) {
        if (getexercisevideo.length - 1 !== playingIndex) {
          setPlayingIndex(duration => ++duration)
        }
        else {
          setPlayingIndex(0)
        }
      }
    }
    else {
      if (getallvideo && getallvideo.length == 1 && getallvideo.some(url => url.duration_minutes == -1) == false) {
        history.push("/Home/new-after-rating")
      }
    }
  }

  return (
    <>
      <section>
        <Nav></Nav>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around ", width: "100%", zIndex: "1001" }}>
          <div
            className={`${shareBox || infoshow || getFeedback
              ? "fixedIntro"
              : "new_intro_box"
              }`}
          >
            <div className="intro_head">
              <div
                onClick={() => history.goBack()}
                style={{ marginLeft: "15px", cursor: "pointer" }}
              >
                <img src={back} alt="back" height="35px" width="35px" />
              </div>
              <div>
                <div className="intro_title_image">
                  <div onClick={openinformation} style={{ cursor: "pointer" }}>
                    <span
                      style={{
                        font: "normal normal normal 16px/19px Rubik",
                        letterSpacing: "0px",
                        color: "#A8A4A4",
                        marginRight: "8px",
                      }}
                    >
                      {t("GOAL_T")}:
                    </span>
                    <span
                      style={{
                        font: "normal normal normal 16px/19px Rubik",
                        letterSpacing: "0px",
                        color: "#FFFFFF",
                        marginRight: "8px",
                      }}
                    >
                    {getexercisevideo[playingIndex].goal_rating
                      ? getexercisevideo[playingIndex].goal_rating.goaltitle
                      : getexercisevideo[playingIndex].goal_title}
                    </span>
                    <Icon
                      icon="ant-design:info-circle-filled"
                      color="#29aae3"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div className={buttondisable == false ? "nowork" : ""}>
                    <button
                      onClick={() => { getvideo() }}
                    >
                      {loops == true ? (
                        <img src={continues} width="20px" height="20px" />
                      ) : (
                        <img src={discontinue}
                          width="20px" height="20px"
                        />
                      )}
                    </button>
                  </div>
                  <div className={buttondisable == false ? "nowork" : ""}>
                    <button
                      onClick={() => {
                        skipvideos();
                      }}
                    >
                      <AiFillStepForward size={20} color="white" />
                    </button>
                  </div>
                  <div className={buttondisable == false ? "nowork" : ""}>
                    <button
                      onClick={() => {
                        likevideos()
                      }}
                    >
                      {(getexercisevideo[playingIndex].is_like == 0 || getexercisevideo[playingIndex].is_like == 2) ? (
                        < img src={motivates} width="20px" height="20px" />

                      ) : (getexercisevideo[playingIndex].is_like == 1 &&
                        <img src={motivatefull}
                          width="20px" height="20px"
                          size={20}
                        />
                      )}
                    </button>
                  </div>
                  <div className={buttondisable == false ? "nowork" : ""}>
                    <button
                      onClick={() => (
                        removedislikevideo()
                      )}
                    >
                      {getexercisevideo[playingIndex].is_like == 2 ? (
                        <img src={demotivatefull}
                          width="20px" height="20px"
                        />

                      ) : ((getexercisevideo[playingIndex].is_like == 1 || getexercisevideo[playingIndex].is_like == 0) &&
                        <img src={demotivates} width="20px" height="20px" />
                      )}
                    </button>
                  </div>

                  <div className={buttondisable == false ? "nowork" : ""}>
                    <button
                      onClick={() => (
                        buttondisable == true ? addRemoveFav() : ""
                      )}
                    >
                      {getexercisevideo[playingIndex].is_favorite == 1 ? (
                        <AiFillHeart id="heart-path" color="white" size={20} />
                      ) : (
                        <AiOutlineHeart
                          id="heart-path"
                          color="white"
                          size={20}
                        />
                      )}
                    </button>
                  </div>
                  <div >
                    <button>
                      <Icon
                        onClick={() => {
                          setShare(true);
                        }}
                        icon="dashicons:share"
                        color="#FFFFFF"
                        width="20"
                        height="20"
                      />
                    </button>
                  </div>
                  <div >
                    <button
                      onClick={() => {
                        getFeedback ? setFeedback(false) : setFeedback(true);
                      }}
                    >
                      <img
                        src={Feedback}
                        alt="Feedback"
                        height="17px"
                        width="17px"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "32px", position: "relative" }}>
              <div onContextMenu={handleContextMenu}>
                <ReactPlayer
                  id="idname"
                  playsinline
                  url={getexercisevideo[playingIndex].exercise_video
                  }
                  config={{
                    file: {
                      attributes: { controlsList: "nodownload" },
                    },
                  }}
                  controls
                  width="100%"
                  height="450px"
                  muted={false}
                  playing={shareBox || getFeedback || infoshow ? false : true}
                  autoplay={true}
                  ref={inputEl}
                  onProgress={(e) => setCurrentTime(e)}
                  loop={loops == true || getallvideo && getallvideo.length == 1 && getallvideo.some(url => url.duration_minutes == -1) == true ? true : false}
                  onEnded={() =>
                    videoend()
                  }
                />
              </div>

              {totalVdoDuration > introVdoDuration && introVdoDuration !== 0 ?
                <button
                  onClick={skipvideo}
                  className={`skipbutton ${getCurrentValue
                    && (getCurrentValue.playedSeconds >= introVdoDuration
                    )
                    ? "shownone"
                    : ""
                    }`}
                  style={{ fontSize: "15px" }}
                >
                  <span className="text">{t("SKIPINTRO")}</span>
                  <span className="forwardimage">
                    <AiFillStepForward style={{ verticalAlign: "middle" }} />
                  </span>
                </button>
                : ""}


            </div>
            <NavLink exact to="/Home/new-after-rating">
              <div className="intro_end_session_but">
                <button> {t("ENDSESSION")}</button>
              </div>
            </NavLink>
          </div>
        </div>
      </section>

      {/* ------Share popup on click------ */}
      <section>
        <div className={shareBox ? "cares2" : "cares"}>
          <SharePopup
            shareBox={shareBox}
            setShareBox={setShare}
            trailer={getexercisevideo[playingIndex].trailer_video
              ? getexercisevideo[playingIndex].trailer_video.trailer
              : getexercisevideo[playingIndex].trailer
            }
            BASE_URL={BASE_URLS}
          />
        </div>
      </section>
      {/* ------this one is popup on feedback------ */}
      <section>
        <div className={`${getFeedback ? "showFeedback" : "hideFeedback"}`}>
          <FeedBackRatting st1={getFeedback} st2={setFeedback} />
        </div>
      </section>
      {/* ------this one is popup on goal------ */}
      <section>
        <div className={infoshow ? "open_goal_about" : "open_goal_about_none"}>
          <div className="introWrappernew">
            <div className="info_goal_body">
              <div className="info_pergoal_titles">
                <div>
                  <h5>{getexercisevideo[playingIndex].goal_rating
                        ? getexercisevideo[playingIndex].goal_rating.goaltitle
                        : getexercisevideo[playingIndex].goal_title}</h5>
                </div>
                <div onClick={openinformation}>
                  <Icon
                    icon="ci:close-big"
                    color="white"
                    width="30px"
                    height="30px"
                    cursor={"pointer"}
                  />
                </div>
              </div>
              <div className="about_per_goal">
              <p>
                {getexercisevideo[playingIndex].goal_rating
                ? getexercisevideo[playingIndex].goal_rating.goal_description
                : getexercisevideo[playingIndex].goal_description
              }
              </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default IntroVdo;

