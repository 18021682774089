
export const TRANSLATIONS_GE = {
  FIRSTTITLE: `Titel
  `,
  TITLEDISC: `Durch die Nutzung von BreathConductor™ stimmen Sie den Allgemeinen Geschäftsbedingungen und der Datenschutzrichtlinie zu, unter denen Sie, soweit angegeben, der Verarbeitung Ihrer personenbezogenen Daten zustimmen.
  `,
  ACCEPT: `Akzeptieren
  `,
  REJECT: `Ablehnen
  `,
  AGETITLE: `In welche Kategorie fallen Sie?
  `,
  SKIPINTRO: `Intro überspringen`,
  NEXTBUTTON: `Weiter
  `,
  SKIPBUTTON: `Umfrage überspringen
  `,
  GENDERTITLE: `Mit welchem Geschlecht identifizieren Sie sich?
  `,
  PREVIOUSBUTTON: `Vorherige
  `,
  LANGUAGETITLE: `Welche Sprache würden Sie bevorzugen?
  `,
  EXPERIENCETITLE: `Wie erfahren sind Sie in der Meditation?
  `,
  SUBMITBUTTON: `Einreichen
  `,
  SIGNUPTITLE: `Bei BreathConductor anmelden
  `,
  ALREADYACC: `Haben Sie bereits ein Konto?
  `,
  SIGNINTITLE: `Bei BreathConductor anmelden
  `,
  ENTERYOURDET: `Geben Sie Ihre Daten unten ein
  `,
  FORGOTPASS: `Haben Sie Ihr Passwort vergessen?
  `,
  SIGNIN: `Anmelden 
  `,
  DONTACCOUNT: `Sie haben noch kein Konto?
  `,
  SIGNUP: `Registrieren
  `,
  EMAILVER_TITLE: `E-Mail-Verifizierung
  `,
  EMAILVER_P: `Die Verifizierungs-E-Mail wurde an die folgende E-Mail-Adresse gesendet
  `,
  RESEND: `Erneut senden
  `,
  LOGOUT: `Abmeldung
  `,
  VERIFIED: `Verifiziert
  `,
  RESETPASSWORD: `Passwort zurücksetzen
  `,
  RESET: `Zurücksetzen 
  `,
  ALGORITHM_TITLE: `Wählen Sie Ihre BreathConductor Bibliothek
  `,
  PRMOTIONAL_T: `Über BreathConductor
  `,
  BYMUBIK_T: `Trailer ansehen
  `,
  FEATUREGOAL_T: `Ausgewählte Ziele
  `,
  FREE7_T: `7 Tage kostenlos testen
  `,
  FEATUREGOAL_SUB_T:
    `Ausgewählte Ziele - Maskenleistung, Lockdown-Müdigkeit, Immunität
    `,
  PRODUCTIVITY_T: `Produktivität
  `,
  COMINGSOON_T: `Demnächst verfügbar
  `,
  COACH_T: `Trainer
  `,
  ALGORITHAMLIBARY_T: `Starter-Library + 1:1-Messaging und individuelle Programmerstellung durch Ihren persönlichen BreathConductor-Coach                                
  `,
  PURCHASE_T: `Kaufen
  `,
  PRMOTIONALSUB_T: `Trailer
  `,
  CARDPAYMENT_TITLE: `⚠️ Der Händlerservice von Muvik Labs wird derzeit gewartet.
  `,
  TEMPORARY_ACCESS_TITLE: `Fordern Sie Zugriff auf BreathConductor an
  `,
  CARDNUMBER_T: `KARTENNUMMER
  `,
  CARDEXPIRE_T: `KARTENLAUFZEIT
  `,
  CARDCVC_T: `KARTEN-CVC
  `,
  SUBSCRIBE_T: `ABONNIEREN
  `,
  TUTORIAL_TITLE: `Tutorial
  `,
  SKIPINTRO_T: `Lernprogramm überspringen
  `,
  ENDSESSION: `Sitzung beenden
  `,
  HI: `Hallo
  `,
  DALIYREMINDER_T: `Möchten Sie täglich daran erinnert werden, für sich selbst zu sorgen?
  `,
  YES: `Ja
  `,
  NO: `Nein
  `,
  SESSION_TITLE: `Sitzungsdesign
  `,
  SESSION_DETAILS_T: `Geben Sie unten Ihre Sitzungsdetails an.
  `,
  SELECTGOAL_T: `Ziel wählen
  `,
  SELECTTIME_T: `Zeit auswählen
  `,
  SELECTNARRATION_T: `Erzählung auswählen
  `,
  SELECTTHEME_T: `Thema wählen
  `,
  START: `Anfang
  `,
  LEAVEFEEDBACK: `Feedback hinterlassen
  `,
  GOAL_T: `Tor
  `,
  TIMEMIN_T: `Zeit (Min.)
  `,
  BREATHPASE_T: `Atemrhythmus (Atemzüge pro Minute)
  `,
  HISTORY: `Verlauf
  `,
  PROGRAM: `Für Sie
  `,
  CLEARALL: `Alles löschen
  `,
  FAVORITES: `Favoriten
  `,
  REMOVEFAVORITE: `Favorit entfernen
  `,
  REMOVEHISTORY: `Sitzung entfernen
  `,
  NARRATION: `Erzählung
  `,
  LIGHT: `Thema
  `,
  FEEDBACK: `Rückmeldung
  `,
  DELETE: `Löschen
  `,
  NOFEEDBACK: `Keine Rückmeldung gefunden
  `,
  REPLAY: `Wiederholen
  `,
  NEWDUPLICATE: `Neues Duplikat
  `,
  SHARE: `Teilen
  `,
  ADDFAVORITES: `Zu Favoriten hinzufügen
  `,
  SETTINGS: `Einstellungen
  `,
  ACCOUNTDETAILS: `Konto-Details
  `,
  LANGUAGE: `Sprache
  `,
  CHANGEPASSWORD: `Passwort ändern
  `,
  MANAGESUBS: `Abonnements verwalten
  `,
  SETREMINDER: `Erinnerung einstellen
  `,
  home: `Heim
  `,
  INVITEFRIEND: `Freunde einladen
  `,
  MYDATA: `Meine Daten
  `,
  LOGGEDIN: `Eingeloggt als:
  `,
  ABOUTUS: `Über uns
  `,
  TERMSOFUSE: `Nutzungsbedingungen
  `,
  SUPPORT: `Support
  `,
  PRIVACY: `Datenschutz
  `,
  DATAPOLICY: `Richtlinie zur Datennutzung
  `,
  EMAILADDRESS: `E-Mail Adresse
  `,
  PHONENUMBER: `Telefon Nummer
  `,
  CATEGORY: `Kategorie
  `,
  GENDER: `Geschlecht
  `,
  MEDITATIONEXPE: `Erfahrung mit Meditation
  `,
  EDIT: `Bearbeiten
  `,
  EDITPHONE: `Telefonnummer (optional)
  `,
  AGE: `Alter
  `,
  SAVE: `Speichern
  `,
  CANCEL: `Abbrechen
  `,
  SUSPEND: `Aussetzen
  `,
  OLDPASSWORD: `Altes Passwort
  `,
  NEWPASSWORD: `Neues Passwort
  `,
  CONFIRMPASSWORD: `Bestätigen Sie das neue Passwort
  `,
  UPDATE: `Aktualisieren
  `,
  VALIDTILL: `Gültig bis
  `,
  PAYDUE: `Zahlung fällig
  `,
  COST: `Preis
  `,
  GOALS: `Ziele
  `,
  ACTION: `Aktion
  `,
  CANCELLED: `Abgebrochen
  `,
  OTHERPLANS: `Andere Pläne
`  ,
  PLAN: `Plan
  `,
  TYPE: `Art
  `,
  DAILYREMINDER: `Tägliche Erinnerung an die Selbstfürsorge
  `,
  REMINDERME: `Erinnern Sie mich am
  `,
  ADDANOTHER: `Weitere hinzufügen
  `,
  INVITE: `Einladen
  `,
  CLEARDATA: `Daten löschen
  `,
  MYDATACLEAR: `Möchten Sie alle Ihre Daten aus der BreathConductor-App löschen?
  `,
  CLEARNOW: `Jetzt löschen
  `,
  DELETEYOURACC: `Ihr Konto löschen
  `,
  PARMANENTDELETE: `Wenn Sie Ihr Konto löschen, werden alle Daten dauerhaft gelöscht.
  `,
  DOWNLOADDATA: `Daten herunterladen
  `,
  OFYOURAPP: `von Ihrer BreathConductor-App
  `,
  TO: `An:
  `,
  CC: `Cc/Bcc:
  `,
  SUBJECT: `Betreff:
  `,
  MESSAGE: `Nachricht:
  `,
  setting_about_title: "About Us",
  setting_about_title_dis: `Boost performance and build resilence effort lessly through
    science-backed breathing AI.`,
  setting_about_health_title: "Build Stress Resilience",
  setting_about_health_disc: `Stress resilience is an essential skill that allows you to
    bounce back and recover from challenges. Science has shown
    that breath training can both lower rates of immediate
    stress and increase long-term resilience. Paced breathing
    works through physiological and psychological mechanisms
    to help you skillfully manage mental and emotional
    responses to stress.`,
  setting_about_mind_title: "Improve Task Focus",
  setting_about_mind_disc: ` Stress in the workplace, and in life, is a serious issue.
    When stressed, our cognitive abilities diminish, making it
    difficult to stay focused. Breath training is one of the
    most effective stress-relieving and mind-enhancing
    practices you can do to optimize functioning/boost
    performance. Paced breathing stimulates the vagus nerve,
    activating a relaxation response that positively impacts
    mind and body, so you can stay calm, focused and
    productive when stressors hit. `,
  setting_about_sleep_title: "Fall Asleep Easier",
  setting_about_sleep_disc: ` Getting to sleep can be challenging-- especially during
    times of increased stress, anxiety and uncertainty. A
    growing number of studies show that slow breathing
    exercises facilitate relaxation and improve sleep. These
    techniques calm the body by engaging the parasympathetic
    nervous system, and quiet the mind by focusing attention
    on the breath. This combination of cognitive and
    physiological down-regulation support falling asleep and
    staying asleep.`,
  HOME: "Home",
  term_title: "Terms & Conditions",
  term_title_disc: `These Terms & Conditions (these “Terms”) contain the terms and conditions on which Muvik Labs, LLC, supplies content, products or services listed on https://breathconductor.com (the “Website”), through the Breath Conductor™ web app (the “App”) or via other delivery methods to you (the Website and such content, products, services and the Apps are collectively referred to herein as the “Product” or “Products”, which may be updated from time-to-time at the sole discretion of Muvik Labs, LLC).`,
  term_copyright: "Copyright",
  term_copyright_disc: ` All material contained in the app is Copyright ©Muvik Labs LLC.
    All rights reserved. All materials (including software and
    content whether downloaded or not) contained in the Products are
    owned by Muvik Labs LLC (or our affiliates and/or third party
    licensors, where applicable), unless indicated otherwise. You
    agree and acknowledge that the materials are valuable property
    and that other than any specific and limited license for use of
    such materials, you shall not acquire any ownership rights in or
    to such materials. The materials may not be used except as
    provided for in these Terms, and any other relevant terms and
    conditions provided to you without our prior written permission.`,
  term_app: "Breath Conductor™ App",
  term_app1: `By downloading or using the Breath ConductorTM app, these terms
    will automatically apply to you – you should make sure therefore
    that you read them carefully before using the app. You’re not
    allowed to copy, or modify the app, any part of the app, audio/
    video content featured in the app, or our trademarks in any way.
    You’re not allowed to attempt to extract the source code of the
    app, reengineer the audio or video content featured within the
    app, and you also shouldn’t try to translate the app into other
    languages, or make derivative versions. The app itself, content
    inside the app, and all the trade marks, copyright, database
    rights and other intellectual property rights related to it,
    still belong to Muvik Labs, LLC.`,
  term_app2: ` Muvik Labs, LLC is committed to ensuring that the app is as
    useful and efficient as possible. For that reason, we reserve
    the right to make changes to the app or to charge for its
    services, at any time and for any reason. We will never charge
    you for the app or its services without making it very clear to
    you exactly what you’re paying for.`,
  term_app3: `The Breath ConductorTM app stores and processes personal data
    that you have provided to us, in order to provide our Service.
    It’s your responsibility to keep your phone and access to the
    app secure. We therefore recommend that you do not jailbreak or
    root your phone, which is the process of removing software
    restrictions and limitations imposed by the official operating
    system of your device. It could make your phone vulnerable to
    malware/viruses/malicious programs, compromise your phone’s
    security features and it could mean that the Breath ConductorTM
    app won’t work properly or at all.`,
  term_app4: `Certain functions of the App will require the App to have an active internet connection.
   The connection can be Wi-Fi, or provided by your mobile network provider. Muvik Labs, LLC shall
    not be responsible for the App not working at full functionality if you do not have access to Wi-Fi
    , or any other means of internet access, or if you do not have a data plan with your mobile network
     provider with a data limit sufficient to allow your device to connect with, and use, our App.`,
  term_app5: `If you’re using the app outside of an area with Wi-Fi, you
    should remember that your terms of the agreement with your
    mobile network provider will still apply. As a result, you may
    be charged by your mobile provider for the cost of data for the
    duration of the connection while accessing the app, or other
    third party charges. In using the app, you’re accepting
    responsibility for any such charges, including roaming data
    charges if you use the app outside of your home territory (i.e.
    region or country) without turning off data roaming. If you are
    not the bill payer for the device on which you’re using the app,
    please be aware that we assume that you have received permission
    from the bill payer for using the app.`,
  term_app6: `Muvik Labs, LLC shall not be responsible for the condition of and the capabilities of your mobile phone device when using our App. It is your responsibility to ensure that your mobile phone device stays charged and has a battery level sufficient to use the App or the Products, as applicable.`,
  term_app7: ` With respect to Muvik Labs, LLC’s responsibility for your use of
    the app, when you’re using the app, it’s important to bear in
    mind that although we endeavour to ensure that it is updated and
    correct at all times, we do rely on third parties to provide
    information to us so that we can make it available to you. Muvik
    Labs, LLC accepts no liability for any loss, direct or indirect,
    you experience as a result of relying wholly on this
    functionality of the app.`,
  term_app8: `At some point, we may wish to update the App. The App is currently available on iOS – the requirements for the system (and for any additional systems we decide to extend the availability of the app to) may change, and you will need to download certain updates if you desire to keep using the App. Muvik Labs, LLC does not guarantee that the version and or operating platform of the App will be compatible with the software you have installed on your device. Nonetheless, you agree to always accept updates to the App when offered to you. `,
  term_app9: `We may also wish to stop providing or updating the App or any of the Products, and we may, 
    within our exclusive right, terminate use of the App or any of the Products at any time without giving 
    advanced notice that we intend to terminate the App or any of the Products. In the event we decide to 
    terminate the App or any of the Products, we will notify all users via an email to the registered email 
    to your account within the App or a push notification to the mobile phone device the App 
    is installed on (the “Notice”). Unless we instruct users otherwise in the Notice, upon 
    receiving the Notice (a) the rights and licenses granted to you in these Terms will end and 
    (b) you must stop using the App, and (if indicated in the Notice) delete it from your device.`,
  term_website_link: "Website Links",
  term_website_link_disc: ` We may provide links to other websites or services for you to
    access. You acknowledge that any access is at your sole
    discretion and for your information only. We do not review or
    endorse any of those websites or services. We are not
    responsible in any way for: (a) the availability of, (b) the
    privacy practices of, (c) the content, advertising, products,
    goods or other materials or resources on or available from, or
    (d) the use to which others make of these other websites or
    services. We are also not responsible for any damage, loss or
    offense caused or alleged to be caused by, or in connection
    with, the use of or reliance on such websites or services.`,
  term_loop: "Clip Sharing",
  term_con_chan: "Changes to This Terms and Conditions",
  term_con_chan_disc: ` We may update our Terms and Conditions from time to time. Thus,
    you are advised to review this page periodically for any
    changes. We will notify you of any changes by posting the new
    Terms and Conditions on this page. These terms and conditions
    are effective as of 2020-07-07.`,
  term_contact: "Contact Us",
  term_contact_disc: `If you have any questions or suggestions about our Terms and
    Conditions, do not hesitate to contact us at`,
  term_loop_disc: `Loops may be shared off of the Breath Conductor if the following
    conditions are applied. If exercise sample loops are to be
    shared in a public forum, on social media, SMS, email, or other
    website or social services, one of the following tags must be
    included: `,
  term_loop_One: `1) Link to Breath ConductorTM app landing page,
    https://breathconductor.xn--com-9o0a`,
  term_loop_Two: `2) Hashtag Breath
    Conductor, “#breathconductor”.`,

  privacy_title: "Privacy Policy",
  privacyTitleDis: `All capitalized terms used but not otherwise defined herein shall have the meanings set forth in
                        the Terms and Conditions, which is accessible at the Website`,
  webSIteLink: ` (https://breathconductor.com)`,
  privacyNextDis: `Muvik Labs, LLC takes user privacy very seriously. This Privacy Policy (the “Privacy Policy”)
      for the Breath Conductor™ SERVICE (the “Service”) is provided by Muvik Labs for a subscription fee.1 
      This Privacy Policy describes (a) the types of information we may collect or that you, as the user,
      may provide when you purchase, download, access, or use the App, and (b) our practices for collecting, 
      using, maintaining, protecting, and disclosing information collected in connection with the Service.`,
  privacyDisService: `If you choose to use our Service, then you hereby agree to the collection and use 
        of certain information in accordance with this Privacy Policy. 
        The personal information that we collect will be used for providing and improving the Service. 
        We will not use or share your information with anyone except as described in this Privacy Policy.`,
  privacyReadCarefully: `Please read this Privacy Policy carefully to understand our policies and practices
   regarding your information and how we will treat it. If you do not agree with our policies and practices,
    do not download, register with, or use this App. By downloading, registering with, or using this App,
    you agree to this Privacy Policy. This Privacy Policy may change from time to time (see, Changes to 
    This Privacy Policy). Your continued use of this App after we revise this Privacy Policy means 
    you accept those changes,so please check this Privacy Policy periodically for updates.`,
  collectionAndUse: `Information Collection and Use`,
  collectionDis: `For a better experience, while using our Service, we may ask you to provide 
    us with certain personally identifiable information, including but not limited to email, and optional 
    information such as age range, gender, meditation experience, feeling rating, feedback on exercises,
    and any other information that is about you but does not 
    identify you that you may provide from time to time by filling in forms`,
  note_to_Draft: " Note to Draft: ",
  noteDisOne: `How does this subscription fee impact this Privacy Policy?  Is the fee collected prior to or 
        after receiving this Privacy Policy and/or the Terms and Conditions?`,
  noteDisTwo: `If there will be any location and/or GPS tracking, additional warnings may be required.
  Need to confirm the applicability.`,
  afterNoteDis: `or surveys in the App. The information that we request will be retained by us to improve 
    the product experience and used as described in this privacy policy.`,
  additionInfo: `Additional information we may also collect includes: (a) [records and copies of 
    your correspondence (including email addresses and phone numbers), if you contact us], (b)
     [your responses to surveys that we might ask you to complete for research purposes], (c)
      [details of transactions you carry out through the App and of the fulfillment of your orders; 
        you may be required to provide financial 
    information before placing an order through the App], and (d) [your search queries on the App].`,
  contributions: `You may also provide information for publication or display ("Posted") 
  on public areas of the app [or websites you access through the App] (collectively, "User Contributions")
  . Your User Contributions are Posted and transmitted to others at your own risk, [although you may set 
    certain privacy settings for such information by [logging into your account profile].`,
  yourValueTrust: `We value your trust in providing us your personal information, 
    thus we are striving to use commercially acceptable means of protecting it. 
     Please be aware that no security measures are perfect or impenetrable. Additionally, 
    we cannot control the actions of third parties with whom you may choose to share your User Contributions.
    Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.`,
  automaticInfo: `[Automatic Information Collection [and Tracking]]`,
  whenYouDownload: `[When you download, access, and use the App, it may use technology to automatically collect:`,
  usageDetails: `Usage Details. When you access and use the App, we may automatically
   collect certain details of your access to and use of the App, including [traffic data,] [location data,] [logs,] 
   [and other] communication data and the resources that you access and use on or through the App.`,
  DeviceInformation: `Device Information. We may collect information about your mobile device
    and internet connection, including the device's unique device identifier, [IP address,] operating
     system, browser type, mobile network information, and the device's telephone number.`,
  StoredInformation: `Stored Information and Files. The App also may access metadata and other 
    information associated with other files stored on your device. 
    This may include, for example, photographs, audio and video clips, personal contacts, and address book information.`,
  LocationInformation: `Location Information. This App [collects/does not collect] real-time information 
    about the location of your device.
    [DESCRIPTION OF LOCATION INFORMATION COLLECTION].]`,
  doNotDownload: `If you do not want us to collect this information [do not download 
    the App or delete it from your device/you may opt out at any time by [instructions to opt out]].
     Note, however, that opting out of the App's collection of location information will disable its location-based features.`,
  useTechnologies: `[We also may use these technologies to collect information about
     your activities over time and across third-party websites, apps, or other online services 
     (behavioral tracking). You may opt out at any time by [instructions to opt out] for information
      on how you can opt out of behavioral tracking on or through this app and how we respond to browser signals and 
        other mechanisms that enable consumers to exercise choice about behavioral tracking.]`,
  othersite: `Links to Other Sites`,
  otherSiteDis: `This Service may contain links to other sites. 
    If you click on a third-party link, you will be directed to that site.
    Note that these external sites are not operated by us. Therefore, we strongly 
    advise you to review the Privacy Policy of these websites. We have no control 
    over and assume no responsibility for the content,
    privacy policies, or practices of any third-party sites or services.`,
  ChildrensPrivacy: "Children’s Privacy",
  forChildren: `The App and the Services offered hereby are not intended for children 
    under 16 years of age, and we do not knowingly collect personal information from children under 16.
    If we learn we have collected or received personal information from a child under 16 without 
    verification of parental consent,we will delete that information from our servers.`,
  parentOrGuardian: `If (a) you are a parent or guardian and you are aware that your 
    child has provided us with personal information or (b) you believe we might 
    have any information from or about a child under 16, please contact us at`,
  mainEmail: " (contact@breathconductor.com) ",
  afterEmail: `so that we may be able to take all necessary actions to delete that information from our servers.`,
  residentsUnder: `California residents under 16 years of age may have additional rights regarding the collection and sale of their 
    personal information (see, “Your State Privacy Rights” below for more information).`,
  privacyRights: `Your State Privacy Rights`,
  additionalRights: `State consumer privacy laws may provide their residents with additional
   rights regarding our use of their personal information. We are providing this supplemental 
   privacy notice to consumers in California, pursuant to the California Consumer Privacy Act of 2018 (“CCPA”).
   The CCPA grants California residents the following rights:`,
  information: `Information. This Privacy Policy describes how we use and share your personal
   information through the Service, including during the past 12 months. This Privacy Policy describes:`,
  sourceInfo: `The sources through which we collect California residents’ personal
   information and the types of personal information
   collected in the “Personal Information We May Collect” section above.`,
  purposes: `The purposes for which we use and share this information in the “How We Use Personal Information” 
    section above and the “How We Share Personal Information” section above.`,
  access: `Access. You can request a copy of the personal information that we maintain about you.`,
  deletion: `Deletion. You can ask to delete the personal information that we maintain about you.`,
  optOut: `Opt out of sale of your personal information. We do not sell personal information. We offer instructions on 
    how to limit online tracking in the Online Tracking Opt Out.`,
  CCPALimits: `The CCPA limits these rights. For example, companies may not provide users in 
    response to access requests certain sensitive information and other consumers’ personal information,
    and may exclude information that would compromise the confidentiality of trade secrets. 
    The CCPA also places limits on when companies must comply with a deletion request.`,
  exerciseRights: `You are entitled to exercise the rights described above free from discrimination.`,
  submitRequest: `Here is how you can submit requests: `,
  shineTheLight: `California's "Shine the Light" law (Civil Code Section § 1798.83) permits users
   of our App that are California residents to request certain information regarding our disclosure 
   of personal information to third parties for their direct marketing purposes.
   To make such a request, please send an email to`,
  verifyIdentity: `To verify your identity prior to responding to your requests,
   we may ask you to confirm your identity by providing us with the email address that 
   you used to create your account within the APP,
   or other details that we may need to verify your identity.`,
  counrtySpecific: `There are also country specific disclosures that may be applicable depending on the
   App’s target audience and whether it is international or not.`,
  authorizedAgents: `Authorized agents. California residents can empower an “authorized agent” 
    to submit requests on their behalf. We will require authorized agents to 
    confirm their identity and authority, in accordance with the CCPA.`,
  changePolicy: `Changes to This Privacy Policy`,
  updatePolicy: `We may update this Privacy Policy from time to time. [If we make material changes
     to how we treat our users' personal information, we will post the new Privacy Policy on this
      page and notify you by email to the primary email address specified in your account and an in-App alert the
   first time you use the App after we make the change.]`,
  periodicallyVisiting: `The date the privacy policy was last revised is identified at the top of the page. You are 
  responsible for ensuring we have an up-to-date active and deliverable [email address] [and/or]
   [phone number] for you and for periodically visiting this privacy policy to check for any changes.`,
  changePolicyLast: `We may update our Privacy Policy from time to time. Thus, 
    you are advised to review this page periodically for any changes. We will notify 
    you of any changes by posting the new Privacy Policy on this page. 
    This policy is effective as of 2020-07-07.`,
  ContactUs: `Contact Us`,
  contactUsAt: `If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at`,
  emailreq: `E-Mail ist erforderlich
  `,
  accesscodereq: `Zugangscode ist erforderlich
  `,
  pentvalemail: `Bitte geben Sie eine gültige E-Mail Adresse ein`
  ,
  passwordrew: `Passwort ist erforderlich
  `,
  signinpwderror: `Das Passwort sollte 8 Zeichen lang sein und einen Großbuchstaben, ein Sonderzeichen und mindestens eine Zahl enthalten.
  `
  , historyconfirmdel: `Sind Sie sicher, dass Sie den gesamten Verlauf löschen möchten?
  `
  , historyallremove: `Alle Einträge erfolgreich gelöscht
  `,
  favouriteremove: `Favoriten erfolgreich entfernt
  `,
  favoriteconfirm: `Sind Sie sicher, dass Sie alle Favoriten entfernen möchten?
  `
  , accountdelsuc: `Konto erfolgreich gelöscht
  `,
  removeal: `Sind Sie sicher, dass Sie alle Daten löschen möchten?
  `,
  exerciseclear: `Trainingsverlauf erfolgreich gelöscht
  `
  , accountupdate: `Informationen erfolgreich aktualisiert
  `,
  selectplan: `Wählen Sie den Plan aus`,
  messagerequire: `Nachricht ist erforderlich
  `,
  subjereq: `Betreff ist erforderlich
  `,
  medmandator: `Das Feld Meditation ist obligatorisch
  `,
  surupdmsg: `Ihre Umfrage wurde erfolgreich aktualisiert
  `,
  remindupdatemsg: `Erinnerung erfolgreich aktualisieren
  `,
  invitaionsent: `Einladung gesendet
  `,
  remthifavour: `Sind Sie sicher, dass Sie diese Sitzung aus den Favoriten entfernen möchten?
  `,
  remthihisto: `Sind Sie sicher, dass Sie diese Sitzung aus dem Verlauf entfernen möchten?
  `,
  histremsucc: `Verlauf erfolgreich entfernt
  `,
  introaddlike: `Favorit erfolgreich hinzugefügt
  `,
  introremlike: `Favorit erfolgreich entfernt
  `,
  veremasuccess: `Verifizierungs - E - Mail erfolgreich gesendet
  `,
  emailversucc: `E - Mail erfolgreich verifiziert
  `,
  pleaseverifyid: `Bitte verifizieren Sie Ihre ID auf Ihrem Gerät
  `,
  enteremail: `E-Mail eingeben
  `,
  enterpasswork: `Passwort eingeben
  `,
  enterconfirm: `Bestätigen Sie das Passwort
  `,
  enteraccesscode: `Geben Sie den Zugangscode ein
  `,
  emailadd: `E-Mail-Addresse`, oldpass: `Altes Passwort

  `,
  newpass: `Neues Passwort
  `,
  confirm: `Bestätigen Sie das neue Passwort`,
  noiternet: `Keine Internetverbindung`,
  suspendplan: `Möchten Sie dieses Abonnement wirklich aussetzen? Sie können es erst wieder kaufen, wenn die aktuelle Laufzeit abgelaufen ist.`,
  socialsuccess: `Soziale Anmeldung erfolgreich`,
  loginsuccess: `Anmeldung erfolgreich`, confirmpassreq: `Passwort bestätigen ist erforderlich`,
  Facebook: `Facebook`,
  whatsapp: `WhatsApp`,
  Linkedin: `LinkedIn`,
  Twitter: `Twitter`,
  notfound: `Not Found`,
  passwordnotmatch: `Passwort ist falsch`,
  enterphone: `Telefonnummer eingeben`,
  deletaccount: `Möchten Sie Ihr Konto wirklich löschen?`,
  enterpassword: `Geben Sie Ihr Passwort ein`,
  Done: `Fertig`,
  entersubject: `Betreff eingeben`,
  message: `Nachricht`,
  na: `NA`,
  Notifications: `Benachrichtigungen`,
  password: `Passwork didn't match`,
  empty_err: "Bitte wählen Sie oben Informationen aus",
  tutorail:`Lernprogramm`,
  skip:`Überspringen`,
  Recommendedspeed:"Empfohlene Geschwindigkeit",
  changelanugage:`Do not want to change language?`,
  calibrate:"Kalibrieren",
  calibrationPrompt:"Wie fühlt sich dieses Tempo an?",
  calibrationTitle:"Kalibrierungssitzung",
  calibrationSubTitle:"Reagieren Sie auf die Eingabeaufforderung, wenn sie angezeigt wird.",
  tooFast:"Zu schnell",
  justRight:"Genau richtig",
  tooSlow:"Zu langsam",
  completeCalibration:"Vollständige Kalibrierung",
  failTitle:"Fehler beim Einstellen der Benutzergeschwindigkeit",
  skipPace:"Zum nächsten Tempo springen",
  shareBc:"Teilen Sie BreathConductor™ mit einem Freund!",
  shareH1:"Resilienz Aufbauen",
  shareB1:"Ihr Atem ist ein leistungsstarkes Instrument zur Senkung des Blutdrucks, der Herzfrequenz und der Kampf-oder-Flucht-Reaktion.",
  shareH2:"Tief Durchatmen",
  shareB2:"Das Üben langsamer Atmung unterstützt alle Aspekte der Gesundheit und des Wohlbefindens und fördert eine optimale Funktion.",
  shareH3:"Erholen Sie sich vom Stress",
  shareB3:"Langsames, rhythmisches Atmen bringt das Nervensystem ins Gleichgewicht und hilft Ihnen, sich von Stress zu erholen.",
  shareH4:"Nutzen Sie die Kraft des Atems",
  shareB4:"Rhythmisches Atmen kann Ihre Herzfrequenz verlangsamen, Ihren Geist beruhigen und Ihr Nervensystem entspannen.",
  signUpClosing:"\n\nMelden Sie sich noch heute für BreathConductor an:",
  signUpLink:"\nbreathconductor.com/signup"
}





