import React, { useEffect, useState } from "react";
import "./Home.css";
import { Container, Row, Col } from "react-bootstrap";
import HomeFooter from "../HomeFooter/HomeFooter";
import HomeHeader from "../HomeHeader/HomeHeader";
import Banner from "./Banner/Banner";
import DataPrivate from "../../Assets/Images/dataPrivateStays.png";
import DigitalBanner from "../../Assets/Images/digitalbanner.png";
import Mlogo from "../../Assets/Images/MLogo.PNG";
import vimoicon from "../../Assets/Images/vimeo_ico.png";
import Insta from "../../Assets/Images/insta1.png";
import Tiktok from "../../Assets/Images/tiktok1.png";
import ContactUs from "../../Assets/Images/contactus.png";
import ScrollTop from "../../Assets/Images/scroll-top.svg";
import CarouselFadeExample from "./slider/slider";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../commonFile"; 
import { useHistory } from "react-router-dom";

const Home = () => {

  const [challengeComplete, setChallengeComplete] = useState(false);
  const [email, setemail] = useState("");
  const getContactUs = async () => {
      let requestData = new FormData();
     requestData.append("email",email);
    await SublyApi.earlyaccess(requestData).then((responsejson) => {
      if (
        responsejson?.data?.status_code == 400 ||
        responsejson?.data?.status_code == 500
      ) {
        Toast.fire({
          icon: "error",
          title: `${responsejson.data.message}`,
        });
      } else {
        document.getElementById("email").value = "";
          Toast.fire({
            icon: "success",
            title: `${responsejson?.message}`,
          });
      }
    });
  };

  function removeCaptcha() {
    var iframes = document.querySelectorAll('iframe[title="Widget containing a Cloudflare security challenge"]');
    iframes.forEach(function(iframe) {
      iframe.parentNode.removeChild(iframe);
    });
  }

  useEffect(() => {
    const onloadTurnstileCallback = () => {
      /* global turnstile */
      turnstile.render("#content", {
        sitekey: process.env.REACT_APP_CF_TURNSTILE_SITEKEY,
        callback: function (token) {
          setTimeout(() => {
      setChallengeComplete(true);
          }, 1000);
        },
      });
    };

    if (!challengeComplete) {
      const script = document.createElement("script");
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
      script.defer = true;
      const captchaMessage = document.createElement("h2");
      captchaMessage.textContent =
        "Checking if the site connection is secure and reviewing the security of your connection before proceeding...";
      captchaMessage.style.position = "absolute";
      captchaMessage.style.top = "calc(50% - 200px)";
      captchaMessage.style.left = "50%";
      captchaMessage.style.transform = "translate(-50%, -50%)";
      captchaMessage.style.textAlign = "center";
      captchaMessage.style.color = "white";
      captchaMessage.style.fontSize = window.innerWidth <= 768 ? "20px" : "35px"; // Adjust the breakpoint and font size values as needed
      const captcha = document.createElement("div");
      captcha.appendChild(script);
      captcha.appendChild(captchaMessage);
      script.onload = onloadTurnstileCallback;
      document.body.appendChild(captcha);

      return () => {
        document.body.removeChild(captcha);
      };
    }
  }, [challengeComplete]);

  useEffect(() => {
    if (challengeComplete) {
      const captcha = document.querySelector("div[data-turnstile-container]");
      if (captcha) {
        captcha.style.opacity = 0;
        document.body.removeChild(captcha);
      }
      removeCaptcha();
    }
  }, [challengeComplete]);

  return (
    <div
    id="content"
    style={{
      display: challengeComplete ? "initial" : "flex",
      justifyContent: challengeComplete ? "initial" : "center",
      alignItems: challengeComplete ? "initial" : "center",
      height: challengeComplete ? "initial" : "100vh"
    }}
    >    {challengeComplete ? (
    <div className="container-fluid pb-5-c px-0 lendingPage">
      <section>
        <HomeHeader />
      </section>
      <section>
        {" "}
        <Banner />
      </section>
      <section>
        <Container fluid className="px-4">
          <div className="col-12">
            <Row>
              <div className="col-sm-12 col-lg-5 d-flex justify-content-center justify-content-lg-start align-items-lg-start">
                <img
                  src={DataPrivate}
                  alt="Data Privacy"
                  className="img-fluid"></img>
              </div>
              <div className="col-sm-12 col-lg-7 d-flex align-items-center align-items-lg-start justify-content-center flex-column">
                <div className="content-wrapper">
                  <h1 className="text-start">Your Data Stays Private</h1>
                  <p className="pt-4 pe-0 pe-lg-4">
                    Muvik Labs keeps your data private. We never share your
                    personal data with anyone unless you explicitly request us
                    to do so. Use our BreathConductor tool with full confidence
                    that your information is private and secure. To learn more,
                    please see our Privacy Policy.
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </section>
      <section className="pt-md-c" id="RespireAr">
        <Container fluid className="px-0 position-relative">
          <div className="digitalMain">
            <div className="col-md-12 wrapper-black">
              <Row>
                {" "}
                <div className="col-md-12 col-lg-8">
                  <div className="digital_col1">
                    <div className="dig_head pt-3 pb-2">
                      Digital therapy addressing anxiety in children RespireAR™
                      “يا رايق” Pilot Program
                    </div>
                    <div className="dig_content pt-4">
                      The Middle East Children’s Institute (MECI), together with
                      Muvik Labs, are excited to announce their first
                      collaboration: a bespoke version of Muvik Labs’ immersive
                      BreathConductor™digital therapy for stress resilience
                      fully customised in Arabic for children and adolescents.
                    </div>
                    <div className="pt-5">
                      <a href="https://respirear.com/" target="blank">
                        <button className="button button1">Learn More</button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="digital_col2 d-flex justify-content-center justify-content-lg-end align-items-center">
                    <div className="dig_head1 d-none d-lg-flex">
                      <img
                        className="img-width"
                        src={DigitalBanner}
                        alt="digitalbanner"
                      />
                    </div>
                    <div className="dig_bg"></div>
                  </div>
                </div>
              </Row>
            </div>
            <div className="wave-img"></div>
          </div>
        </Container>
      </section>
      <section className="mt-4 pt-md-c" id="aboutus">
        <Container fluid className="px-0">
          <div className="col-12">
            <Row>
              <div className="col-md-12 col-lg-6 wrapper-black p-5">
                <div className="mLogo ps-3">
                  <img className="logo_img" src={Mlogo} alt="mlogo" />
                </div>
                <h1 className="txt-white pt-1 ps-0 ps-lg-3">Who we are</h1>
                <p className="txt-white lh-lg-c fw-light pe-0 pe-lg-5 pt-4  ps-0 ps-lg-3">
                  {/* Muvik is a leading software company that specializes in
                  digital therapy solutions. Through Augmented Sound™ adaptive
                  infrastructure we produce cognitive training tools which
                  facilitate a unique, generative music-based framework for
                  multi-sensory wellness coaching. With a focus on mental health
                  (anxiety & trauma), Muvik empowers people to reach their goals
                  through seamless and intuitive auditory feedback. */}Muvik AI is a leading software company specializing in digital technology solutions for anxiety and resilience.  Through our Augmented SoundTM adaptive infrastructure we create personalized tools which facilitate unique, generative music-based, paced-breathing experiences.  Our multi-sensory technology has demonstrated on-demand reduction in anxiety while building resilience and empowers individuals to reach their goals through seamless and intuitive auditory feedback.
                </p>
              </div>
              <div className="col-md-12 col-lg-6 wrapper-blue pb-2 pt-5">
                <h1 className="txt-white px-5 pt-small pt-md-c">
                  {/* Where we are going */} Our Solutions
                </h1>
                <p className="txt-white lh-lg-c fw-light pt-4 px-5">
                  {/* BreathConductor™ empowers organizations and health
                  professionals with tools to optimize treatment outcomes and
                  monitor patient progress. Using our Precision Respiration
                  infrastructure, practitioners are able to generate their own
                  interventions and curate unique personalized programs for
                  their users within minutes. */}BreathConductor™ empowers individuals to effectively manage their anxiety and its negative side effects; offers health providers with a simple tool to augment mental health treatment plans; and organizations with a cost effective means to improve employee well being while increasing productivity. Using our precision respiration infrastructure, health providers and instructors can brand and add additional personalization to their patient/client experience, while monitoring engagement.

                  <span className="d-flex txt-white fw-bold pt-4">Our services/products</span>
                  <a
                    className="txt-white fst-italic fw-light"
                    href="https://muviklabs.io"
                    target={"blank"}>
                    muviklabs.io
                  </a>
                  {/* <a
                    className="txt-white fst-italic fw-light"
                   onClick={()=>history.push("/SignUp")} >
                    muviklabs.io
                  </a> */}
                </p>

                <div className="gradient-linear p-3 ps-5">
                  <div className="col-12">
                    <div className="socialmediaimg">
                      <span className="pe-3 text-decoration-underline">
                        Follow us
                      </span>
                      <a
                        href="https://www.instagram.com/breathconductor"
                        target={"blank"}>
                        <img className="socialmedia" src={Insta} alt="instagram" />
                      </a>
                      <a
                        href="https://www.tiktok.com/@breathconductor"
                        target={"blank"}>
                        <img className="socialmedia" src={Tiktok} alt="tiktok" />
                      </a>
                      <a
                        href="https://vimeo.com/user109700786"
                        target={"blank"}>
                        <img className="socialmedia" src={vimoicon} alt="vimoicon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </section>
      <section className="mt-3 pt-md-c" id="Libraries">
        <Container fluid className="card-grp">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            <div className="col ps-0">
              <div className="card card-white no-hover d-flex align-items-start align-items-lg-center justify-content-start h-100">
                <div className="card-body d-flex justify-content-center flex-column">
                  <h5 className="card-title title-lg">Libraries</h5>
                  <p className="card-text">
                    Explore different BreathConductor <br />
                    algorithms for different goals
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <div className="card-body p-0">
                  <div className="card-pointer">
                    <p className="txt-white my-0 fw-light txt-position ps-3">
                      Available Now
                    </p>
                  </div>
                  <div className="p-4">
                    <h5 className="card-title">Relax on Demand</h5>
                    {/* <p className="card-text">
                      Science shows that chronic activation of the stress
                      response impairs overall health wellbeing. The simplest
                      and most effective way to turn off the “fight or flight”
                      response and shift into a relaxed state is to regulate
                      your breathing. Practice paced breathing to gain agency
                      over your response to stress.
                    </p> */}
                     <p className="card-text">
                      Science shows that chronic activation of the stress
                      response impairs overall health wellbeing. The simplest
                      and most effective way to turn off the “fight or flight”
                      response and shift into a relaxed state is to regulate
                      your breathing. Practice paced breathing to gain control
                      over your response to stress.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <div className="card-body p-0">
                  <div className="card-pointer-gray">
                    <p className="txt-gray my-0 fw-light txt-position ps-3 fw-normal">
                      Coming Soon...
                    </p>
                  </div>
                  <div className="p-4">
                    <h5 className="card-title">Find Your Inner Balance</h5>
                    <p className="card-text">
                      Breath regulation supports physical, mental and emotional
                      well-being. For example, when you are too amped up, slow,
                      rhythmic breathing can settle you down. Conversely, when
                      you’re unable to rally, it can energize you. Paced
                      breathing is a profoundly adaptive tool for shifting your
                      internal state and allowing you to restore balance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <div className="card-body p-0">
                  <div className="card-pointer-gray">
                    <p className="txt-gray my-0 fw-light txt-position ps-3 fw-normal">
                      Coming Soon...
                    </p>
                  </div>
                  <div className="p-4">
                    <h5 className="card-title">Optimize Your Performance</h5>
                    <p className="card-text">
                      Paced breathing stimulates the vagus nerve, activating the
                      brain and nervous system in a way that positively impacts
                      performance allowing you to maintain calm, focus and
                      productivity, even when stressors hit. Regular practice
                      helps optimize your functioning and boost your
                      performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <div className="card-body p-0">
                  <div className="card-pointer-gray">
                    <p className="txt-gray my-0 fw-light txt-position ps-3 fw-normal">
                      Coming Soon...
                    </p>
                  </div>
                  <div className="p-4">
                    {/* <h5 className="card-title">Build Pandemic Grit</h5> */}
                    <h5 className="card-title">Build Grit</h5>
                    <p className="card-text">
                      Resilience is our ability to not only bounce back from
                      stress, but also to grow and thrive during times of
                      adversity. Rhythmic breathing can build your capacity to
                      remain centered in each moment, increase feelings of
                      agency and support self-care during unprecedented times.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <div className="card-body p-0">
                  <div className="card-pointer-gray">
                    <p className="txt-gray my-0 fw-light txt-position ps-3 fw-normal">
                      Coming Soon...
                    </p>
                  </div>
                  <div className="p-4">
                    {/* <h5 className="card-title">Crush The Workplace</h5> */}
                      <h5 className="card-title">Thrive in the workplace</h5>
                    {/* <p className="card-text">
                      Coming soon Stress in the workplace impacts our cognitive
                      functioning and ability to flourish. Professionals in
                      high-stakes or high-stress fields need every advantage to
                      make their biggest impact. Optimizing your breathing is a
                      key tool to support you in performing with skill so you
                      can do and feel your best in the workplace.
                    </p> */}
                    <p className="card-text">
                    Even for the most prepared, stressful situations will appear in your workplace.  Being able to mitigate the negative effects will benefit your wellbeing and return focus to the needs at hand.  Optimizing your breathing is a key tool to support the performance of you and your team to thrive in the moment and into the future.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="mt-5 mb-3 pt-md-c" id="Testimonials">
        <Container>
          <Row>
            <CarouselFadeExample />
          </Row>
        </Container>
      </section>

      <section className="mt-5" id="Contactus">
        <Container fluid className="px-5 pe-0 ps-0 ps-lg-5">
          <Row>
            <div className="col-md-12 col-lg-6 px-4 d-flex justify-content-center flex-column">
              <h2 className="title-md">Join Mailing List</h2>
              {/* <p className="details">We're here for you</p> */}
              <p className="details">We are here to help you be your best</p>
              <div className="col-12 mb-4 mb-lg-0 d-flex">
                <div className="inputset form-field position-relative">
                  <input
                    type="email"
                    id="email"
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                    placeholder="Email Address"
                  />
                  <button
                    type="button"
                    className="btn-secondary-c btn-rounded d-flex align-items-center justify-content-center"
                    onClick={(e) => {
                      getContactUs();
                    }}>
                    <span className="d-flex d-lg-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right position-relative"
                        viewBox="0 0 16 16">
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                    <span className="d-none d-lg-block">Submit</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-6">
              <img className="contactImg" src={ContactUs} alt="contactImg" />
            </div>
          </Row>
        </Container>
      </section>

      <div className="scrolltop-wrap">
        <a href="#" role="button" aria-label="Scroll to top">
          <img
            src={ScrollTop}
            alt="Scroll Top"
            className=""
            id="scrolltop-arrow"></img>
        </a>
      </div>
      <HomeFooter />
    </div>
    ) : null}
  </div>
  );
};
export default Home;
