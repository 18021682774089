import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./MyFavorites.css";
import clearBtn from "../../Assets/Images/closeIcon.svg";
import Nav from "../../Components/Header/header";
import SublyApi from "../../.../../helpers/Api";
import { NavLink } from "react-router-dom";
import NextArrow from "../../Assets/Images/next.svg";
import { confirm } from "react-confirm-box";
import { Toast } from "../../commonFile";
import { logout, deleteToken } from "../../actions/currentUser";
import { Loader } from "../../commonFile";
import {
  deleteDefaultList,
  deleteNewDefaultList,
} from "../../actions/defaultList";
import { Getlanguage } from "../../commonfunction";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getinternet } from "../../commonfunction";
import { clearErr } from "../../actions/errors";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";

function MyFavorites() {
  const [resultfail, setresultfail] = useState(false)
  const [favoriteList, setFavoriteData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notfound, setNotFound] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((st) => st.token);
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };


  useEffect(() => {
    async function getTodos() {
      setLoading(true);
      const favoriteData = await SublyApi.exerciseFavorite(token, currentUser.device_id, currentUser.device_timezone);
      if (
        favoriteData &&
        favoriteData.data.error_type === "USER_NOT_FOUND" &&
        favoriteData.data.status_code === 400
      ) {
        // Toast.fire({
        //   icon: "error",
        //   title: favoriteData.data.message,
        // });
        signOut();
      }
      else {
        if (favoriteData) {
          if (favoriteData.data.data_found === false) {
            setLoading(false);
            setNotFound(favoriteData.message);
          } else {
            setLoading(false);
          }
        }
        setFavoriteData(favoriteData ? favoriteData.data.favorite_exercise_list : "");
      }
    }
    getTodos(favoriteList);
  }, [resultfail === true]);

  const clearAllFavorite = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      const result = await confirm(
        t('favoriteconfirm')
      );
      if (result) {
        await SublyApi.removeAllFavorite(token, currentUser.device_id, currentUser.device_timezone).then((responsejson) => {
          if (
            responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400
          ) {
            signOut();
          }
          else {
            if (responsejson.data.status_code == 400) {
              Toast.fire({
                icon: "error",
                title: responsejson.data.message,
              });
            } else {
              Toast.fire({
                icon: "success",
                title: t("favouriteremove"),
              });
              setresultfail(true)
            }
          }
        })
          .catch(() => { });


      }
    }
  };




  return (
    <section className="FavoriteBody">
      <Nav></Nav>
      <div className="historyflexbody" >

        <div className="historypopup">
          <div className="FavoriteContain">
            <div className="Title_ClrBtn">
              <div className="Title">
                <h2>{t("FAVORITES")}</h2>
              </div>
              {favoriteList ? (
                <div className="ClrBtn">
                  <a
                    href="javascript:void(0);"
                    onClick={favoriteList ? clearAllFavorite : ""}
                  >
                    <img src={clearBtn} alt="favcle" />

                    <span>{t("CLEARALL")}</span>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
            <span>
              {loading ? <Loader /> : null}
            </span>
            <div className="MyFavList">
              <div className="HistList">
                {favoriteList ? (
                  favoriteList.map((item, index) => (
                    <div className="FavItem">
                      <NavLink
                        key={index}
                        exact

                        to={`/MyFavorites/FavoriteDetail/${item.exercise_id}`}
                      >
                        <div className="FavText">
                        <h3>{item.exercise.title && item.exercise.title.toLowerCase()} </h3>
                        </div>
                        <div className="HistIcon">
                          <span>{item.exercise.goal} -
                            {moment.utc(item.created_at).local().format("YYYY-MM-DD | hh:mm:ss")}</span>
                          <img src={NextArrow} alt="arrow" />
                        </div>
                      </NavLink>
                    </div>
                  ))
                ) : (
                  <p>{notfound}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

    </section>
  );
}

export default MyFavorites;
