import React from "react";
import "./Setting.css";
import "./DeleteConfirm.css";
import "./SharePopUp.css";
import { NavLink, useHistory } from "react-router-dom";
import Nav from "../../Components/Header/header";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { Getlanguage } from "../../commonfunction";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import SublyApi from "../../helpers/Api"
import { useState } from "react";
import { Removeall } from "../../commonfunction";
import { useDispatch } from "react-redux";
import { deleteToken } from "../../actions/currentUser";
import { logout } from "../../actions/currentUser";
import { deleteDefaultList } from "../../actions/defaultList";
import { clearErr } from "../../actions/errors";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";
import { deleteNewDefaultList } from "../../actions/defaultList";


export function NewSetting() {
  const [url, seturl] = useState();
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  const token = useSelector((st) => st.token);
  const history = useHistory();
  const dispatch = useDispatch();
  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  //it's api term & privacy policy work 
  useEffect(() => {
    async function terms() {
      const term = await SublyApi.termconditions(currentUser.device_id, currentUser.device_timezone);
      if (
        term &&
        term.data.error_type === "USER_NOT_FOUND" &&
        term.data.status_code === 400
      ) {
        signOut();
      }
      else {
        if (term &&
          term.data.status_code === 400) {
        }
        if (term &&
          term.status_code === 200) {
          seturl(term.data.urls)
        }
      }
    }
    terms();
  }, []);
  return (
    <>
      <section>
        <Nav></Nav>
        <div className="Ratingfullbody" >
          <div className="newsettingbody">
            <h2 className="settingtitle">{t("SETTINGS")}</h2>
            <div className="newsettingbutton" style={{ marginTop: "30px" }} >
              <NavLink to="/Setting/account-setting" tabIndex="-1">
                <button>
                  <div className="settingtitleper">
                    <p>{t("ACCOUNTDETAILS")}</p>
                  </div>
                  <div className="newsettingsvg">
                    <Icon
                      icon="ic:outline-navigate-next"
                      color="white"
                      width="20"
                      height="20"
                      style={{ verticalAlign: "middle" }}
                    />
                  </div>
                </button>
              </NavLink>
              <NavLink exact to={`/Setting/Language-setting/${0}`} tabIndex="-1">
                <button >
                  <div className="settingtitleper">
                    <p>{t("LANGUAGE")}</p>
                  </div>
                  <div className="newsettingsvg">
                    <Icon
                      icon="ic:outline-navigate-next"
                      color="white"
                      width="20"
                      height="20"
                      style={{ verticalAlign: "middle" }}
                    />
                  </div>
                </button>
              </NavLink>
              {currentUser.social_accounts.socialAccountID ? (
                ""
              ) : (
                <NavLink exact to="/Setting/Password-change" tabIndex="-1">
                  <button>
                    <div className="settingtitleper">
                      <p>{t("CHANGEPASSWORD")}</p>
                    </div>
                    <div className="newsettingsvg">
                      <Icon
                        icon="ic:outline-navigate-next"
                        color="white"
                        width="20"
                        height="20"
                        style={{ verticalAlign: "middle" }}
                      />
                    </div>
                  </button>
                </NavLink>
              )}
              <NavLink exact to="/Setting/Manage-subscriptions" tabIndex="-1">
                <button >
                  <div className="settingtitleper">
                    <p>{t("MANAGESUBS")}</p>
                  </div>
                  <div className="newsettingsvg">
                    <Icon
                      icon="ic:outline-navigate-next"
                      color="white"
                      width="20"
                      height="20"
                      style={{ verticalAlign: "middle" }}
                    />
                  </div>
                </button>
              </NavLink>
              <NavLink exact to="/Setting/SetReminder" tabIndex="-1">
                <button >
                  <div className="settingtitleper">
                    <p>{t("SETREMINDER")}</p>
                  </div>
                  <div className="newsettingsvg">
                    <Icon
                      icon="ic:outline-navigate-next"
                      color="white"
                      width="20"
                      height="20"
                      style={{ verticalAlign: "middle" }}
                    />
                  </div>
                </button>
              </NavLink>
              <NavLink exact to="/Setting/Invite-friend" tabIndex="-1">
                <button >
                  <div className="settingtitleper">
                    <p>{t("INVITEFRIEND")}</p>
                  </div>
                  <div className="newsettingsvg">
                    <Icon
                      icon="ic:outline-navigate-next"
                      color="white"
                      width="20"
                      height="20"
                      style={{ verticalAlign: "middle" }}
                    />
                  </div>
                </button>
              </NavLink>
              <NavLink exact to="/Setting/MyData" tabIndex="-1">
                <button >
                  <div className="settingtitleper">
                    <p>{t("MYDATA")}</p>
                  </div>
                  <div className="newsettingsvg">
                    <Icon
                      icon="ic:outline-navigate-next"
                      color="white"
                      width="20"
                      height="20"
                      style={{ verticalAlign: "middle" }}
                    />
                  </div>
                </button>
              </NavLink>
            </div>
            <div className="costumeridname">
              <p>{t("LOGGEDIN")} {currentUser.email}</p>
            </div>
            <div className="newsetsuppli">
              <ul className="new_Support_Links">
                <li className=" newAboutus" style={{ marginRight: "40px" }}>
                  <NavLink exact to="/Setting/AboutUs">
                    <p className="new_Support_Links_Title">{t("ABOUTUS")}</p>
                  </NavLink>
                </li>
                <li className="new_Support_Links_Title responsivenonshow">|</li>
                <li className="newTermofuse">
                  <a href={url ? url.terms_of_use : ""} target="blank">
                    <p className="new_Support_Links_Title">{t("TERMSOFUSE")}</p>
                  </a>
                </li>
                <li className="new_Support_Links_Title responsivenonshow">|</li>
                <li className="newSupport">
                  <NavLink exact to="/Setting/Support">
                    <p className="new_Support_Links_Title">{t("SUPPORT")}</p>
                  </NavLink>
                </li>
                <li className="new_Support_Links_Title responsivenonshow">|</li>
                <li className="newprivacy">
                  <a href={url ? url.privacy_policy : ""} target="blank">
                    <p className="new_Support_Links_Title ">{t("PRIVACY")}</p>
                  </a>
                </li>
                <li className="new_Support_Links_Title responsivenonshow">|</li>
                <li className=" newDatapolicy">
                  <a href={url ? url.terms_of_use : ""} target="blank">
                    <p className="new_Support_Links_Title" >{t("DATAPOLICY")}</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewSetting;
