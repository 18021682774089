import React, { useEffect, useState } from "react";
import "./ManageSubscription.css";
import { Icon } from "@iconify/react";
import Nav from "../../Header/header.js";
import { useHistory } from "react-router-dom";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { Toast } from "../../../commonFile";
import { Loader } from "../../../commonFile";
import SubPaymentForm from "./paymentCard";
import { Getlanguage } from "../../../commonfunction";
import { useTranslation } from "react-i18next";
import { getinternet } from "../../../commonfunction";
import { confirm } from "react-confirm-box";
import { deleteToken } from "../../../actions/currentUser";
import { logout } from "../../../actions/currentUser";
import { deleteDefaultList } from "../../../actions/defaultList";
import { clearErr } from "../../../actions/errors";
import { deleteExcerciseList } from "../../../actions/getExcercise";
import { deleteExperience } from "../../../actions/getexperiense";
import { deleteGender } from "../../../actions/getgender";
import { deleteLanguage } from "../../../actions/getlanguage";
import { deleteAge } from "../../../actions/getsurvey";
import { deletTimeNarration } from "../../../actions/timeNarrationList";
import { removeTempData } from "../../../actions/tempData";
import { deleteNarrationTheme } from "../../../actions/narrationTheme";
import { deleteDefault } from "../../../actions/homeSession";
import { deleteGoalTimeList } from "../../../actions/goalTimeList";
import { deleteNewDefaultList } from "../../../actions/defaultList";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import SelectInput from "@material-ui/core/Select/SelectInput";

function ManageSubscription() {
  const [datapack, setdatapack] = useState([]);
  const [subdatapack, setsubdatapack] = useState([]);
  const [feature, setfeature] = useState();
  const [getcheckrest] = useState(true);
  const [Relax, setRelax] = useState("");
  const [aftersubs, setaftersubs] = useState();
  const [checkboxId, setCheckboxShow1] = useState([]);
  const [openBox, setOpenBox] = useState(false);
  const [subValue, setSubValue] = useState();
  const [resultfail, setresultfail] = useState(false)
  const token = useSelector((st) => st.token);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [planToPurchase, setPlanToPurchase] = useState(null);

  const handlePurchaseClick = (pack) => {
    setPlanToPurchase(pack);
    setShowPopup(true);
  };

  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  useEffect(() => {
    async function getsubplandata() {
      setsubdatapack(null);
      const subdatapacks = [...subdatapack]
      const subbedPlans = await SublyApi.SubscribedUserPlans(token, currentUser.userID);
      subbedPlans.data.user_plans_data.forEach(element => {
        if (element !== null) {
          subdatapacks.push(element);
        }
      });
      if(subdatapacks.length > 0) {  
        setsubdatapack(subdatapacks);
      }
    }
    getsubplandata();
  }, []);

  useEffect(() => {
    async function getplandata() {
      const datapacks = [...datapack]
      const getplan = await SublyApi.planSubscription(token ? token : currentUser.auth_token, currentUser.device_id, currentUser.device_timezone);
      const specialPlans = await SublyApi.WhitelistedUserPlans(token, currentUser.email);
      if (
        getplan &&
        getplan.data &&
        getplan.data.error_type == "USER_NOT_FOUND" &&
        getplan.data.status_code === 400
      ) {
        // Toast.fire({
        //   icon: "error",
        //   title: getplan.data.message,
        // });
        signOut();
      }
      else {
        if (getplan && getplan.data && getplan.data.plan_data) {
          var specialPlanIDvalues = specialPlans.data.whitelisted_plans.map(plan => plan.planID);
          getplan.data.plan_data.forEach(element => {
            if (!subdatapack.map(element => element.id).includes(element.id) && (element.plan_type == 1 || specialPlanIDvalues.includes(element.id))) { 
              if (element !== null) {
                datapacks.push(element);
              }
            }
          });
        }
        setdatapack(datapacks)
        setfeature(getplan ? getplan.data.feature_goals : "")
      }
    }
    getplandata();
  }, []);

  // ----logOut while token expired----

  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };

  // ----API for suspend subscription plan----
  const suspendPlan = async (data) => {
    const online = await getinternet();

    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setresultfail(false)
      document.body.classList.add("addclassmanage");
      const result = await confirm(t("suspendplan"));

      if (result == true) {

        let requestData = new FormData();
        requestData.append("user_subscription_id", data[0]);
        requestData.append("plan_name", data[1]);
        await SublyApi.subcriptionSuspend(token, requestData, currentUser.device_id, currentUser.device_timezone)
          .then((responsejson) => {
            if (responsejson &&
              responsejson.data.error_type == "USER_NOT_FOUND" &&
              responsejson.data.status_code === 400) {
              // Toast.fire({
              //   icon: "error",
              //   title: responsejson.data.message,
              // });
              signOut();
            }
            else {
              if (responsejson.data.status_code === 400) {
                Toast.fire({
                  icon: "error",
                  title: responsejson.data.message,
                });
              } else {
                if (result) {
                  Toast.fire({
                    icon: "success",
                    title: "Suspension succeeded! Please wait for a confirmation email from Muvik AI",
                    timer: 5000
                  });
                  setresultfail(true);
                }
              }
            }
          })
          .catch(() => { });
      }
    }
  };

  return (
    <>
      <section className="backgroundwhite">
        <Nav></Nav>
        <div
          className={`${subValue
            ? "manageSub_Conatainer"
            : "manageSub_Conatainer heightManage"
            }`}
        >
          <div className="manageSub_Body">
            <div className="manageSub_Header">
              <div className="magageSubBack_Icon">
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="white"
                  width="24"
                  cursor="pointer"
                  height="24"
                  onClick={() => {
                    history.push("/setting");
                  }}
                />
              </div>
              <div className="manageSub_Title">
                <h5>{t("MANAGESUBS")}</h5>
              </div>
            </div>
              <div className="main_Plan">
                  <table className="main_Plan_head ">
                    <thead>
                      <tr>
                        <th style={{ width: "17%" }}>{t("PLAN")}</th>
                        <th style={{ width: "17%" }}>
                          {t("PAYDUE")}
                        </th>
                        <th style={{ width: "10%", paddingRight: "270px", textAlign: "right" }}>
                          {t("COST")}
                        </th>
                        <th style={{ width: "15%" }}>
                          {t("ACTION")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {datapack && datapack.length > 0 && (
                    <>
                    {/*Don't include plans for purchase that the user already has an active subscription to, unless it was suspended and is simply finishing its term*/}
                    {datapack.filter(pack => !subdatapack || !subdatapack.some(subpack => Number(subpack.id) === Number(pack.id) && Number(subpack.state) === 1 && subpack.plan_cancel_date === null)).map(pack => (
                      <tr className="first_Plan" >
                        <td>{pack?.plan_name}</td>
                        <td>-</td>
                        <td style={{ width: "10%", paddingLeft: "6px", textAlign: "left" }}>${pack?.plan_price}</td>
                        <td>
                        <button
                          onClick={() => handlePurchaseClick(pack)}
                          //If one of the user's active plans matches this plan to purchase, it must be in its term and we must let it expire
                          disabled={
                            subdatapack?.some(function(item) {
                              return item.id === pack.id && Number(item.state) === 1 && item.plan_cancel_date !== null;
                            }) ? true : false
                          }
                          style={
                            subdatapack?.some(function(item) {
                              return item.id === pack.id && Number(item.state) === 1 && item.plan_cancel_date !== null;
                            }) ? { filter: "brightness(50%)", pointerEvents: "none" }
                              : {}
                          }
                        >
                          {t("PURCHASE_T")}
                        </button>
                        </td>
                      </tr>
                    ))}
                    </>
                    )}
                    {subdatapack && subdatapack.length > 0 && (
                      <>
                    {subdatapack?.filter(pack => Number(pack.state) === 1 && pack.plan_cancel_date === null).map(pack => (
                      <tr className="first_Plan" >
                        <td>{pack?.plan_name}</td>
                        <td>
                          <Moment format="D MMMM YYYY">
                            {pack?.payment_due_date}
                          </Moment>
                        </td>
                        <td style={{ width: "10%", paddingLeft: "6px", textAlign: "left" }}>${pack?.plan_price}</td>
                      
                        <td onClick={() => {
                              suspendPlan([pack?.payment_gateway_transaction_id, pack?.plan_name]);
                            }}>
                                <button style={{backgroundColor: "#e02020", border: "none"}}>
                                  {t("Suspend")}
                                </button>
                            </td>
                      </tr>
                    ))}
                      </>
                    )}
                  </tbody>
                  </table>
                </div>
          </div>
        </div>
      </section>
      <div>
      {planToPurchase && (
          <div className={showPopup == false ? " open_goal_about_none" : "open_goal_about"} >
            <div id="payment" className="payment_body">
              <div style={{ cursor: "pointer", float: "right"}}>
                <Icon onClick={() => setShowPopup(false)} icon="bytesize:close" color="black" width="20" height="20" />
              </div>
              <h1>{planToPurchase?.plan_name}</h1>
              <h2>Total due: ${planToPurchase?.plan_price} per {planToPurchase?.plan_interval} after 7 days</h2>
              <br></br>
              <PayPalScriptProvider options={{ 
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                intent: "subscription",
                vault: true,
              }}>
                <PayPalButtons
                  createSubscription={(data, actions) => {
                    return actions.subscription.create({
                      /* Creates the subscription */
                      plan_id: planToPurchase.plan_id,
                      custom_id: currentUser.userID+","+planToPurchase.id
                    });
                  }}
                  onApprove={(data, actions) => {
                    Toast.fire({
                      icon: "success",
                      title: "Purchase succeeded! Please wait for an email from Muvik AI",
                      timer: 5000
                    });
                    setShowPopup(false);
                  }}
                />
              </PayPalScriptProvider>        
            </div>
          </div>
      )}
        </div>
    </>
  );
}

export default ManageSubscription;
